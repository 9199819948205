// // import 'firebase/database';
// import "firebase/storage";
// import "firebase/auth";
// import "firebase/firestore";

// import firebase from "firebase/app";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

import { initializeApp } from "firebase/app";
// import * as firebaseAuthMethods from "firebase/auth";

import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { getStorage, ref } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyA1x0yPLoUj6LuTmjDgO95fuEothyp1hi8",
  authDomain: "exam-3125b.firebaseapp.com",
  projectId: "exam-3125b",
  storageBucket: "exam-3125b.appspot.com",
  messagingSenderId: "427692494396",
  appId: "1:427692494396:web:85d294306961c326627d39",
  measurementId: "G-V3H2TZ5P30",
};

const firebaseConfigDevelopment = {
  apiKey: "AIzaSyDZYbXv7RB2Dziwi_k50xEF6wAaiOBRWj8",
  authDomain: "osis-development.firebaseapp.com",
  projectId: "osis-development",
  storageBucket: "osis-development.appspot.com",
  messagingSenderId: "1049476848806",
  appId: "1:1049476848806:web:6fad8ef8d259ce587de867",
  measurementId: "G-EJWZFB0KF0",
};

const firebaseApp = initializeApp(firebaseConfig);
export const db = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);

// firebase.initializeApp(firebaseConfig);
// // export const firebaseDatabase = firebase.database();
// export const firebaseStorage = firebase.storage();
// export const firebaseAuth = firebase.auth();
// export const db = firebase.firestore();
