import React, { Component } from "react";
import { connect } from "react-redux";
import { HomeTemplate } from "../../components/templates/homeTemplate/homeTemplate";
import { setNotification } from "../../store/slices/examSlice";
import Indir from "../../components/organisms/exams/exampleExcelFile";

import {
  getQuestions,
  fetchExam,
  saveExam,
  saveQuestionsToExamFromQuestionBank,
  addParticipantsToExam,
} from "../../db/examMethods";
import {
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Grid,
  Paper,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  Switch,
} from "@mui/material";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Fab from "@mui/material/Fab";
import SaveIcon from "@mui/icons-material/Save";
import { Link } from "react-router-dom";
import QuestionCard from "../../components/organisms/questions/questionCard";
import QuestionModal from "../../components/organisms/questions/questionModal";
import { AddQuestionToExam } from "./addQuestionToExam";
import {
  getUserQuestions,
  fetchExamQuestionsId,
  saveQuestionTitleAndOrder,
} from "../../db/examMethods";
import * as XLSX from "xlsx";
import CircularProgressWithLabel from "../../components/molecules/materialBackDrop/circularProgress";
/**
 Bu arkadaşın 2 işlevi var ancak şu anda sadece birini yerine getirmekte. Biri sıfırdan bir soru oluşturmak istenildiğinde 
 bu sayfayı kullanacağız ancak daha onu yapmadık. Diğer özelliği ise oluşturulmuş bir sınavı editlemek ve içerisine yeni 
 soruların eklenmesini sağlamak.
 */
export class CreateAndEditExamPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exam: {},
      questions: [],
      questionsFromBank: [],
      questionModal: false,
      questionBank: false,
      importedParticipantPercentage: 100,
      display_finish_button: false,
    };
    this.exam_questions_id = "";
  }
  async componentDidMount() {
    const exam_id = this.props.match.params.id;
    const exam = await fetchExam(exam_id);
    this.exam_questions_id = await fetchExamQuestionsId(exam_id);
    this.getQuestions();
    this.setState({ exam });
  }

  getQuestions = async () => {
    const { user } = this.props;
    const filter = {
      exam_id: this.props.match.params.id,
      creator_id: user?.uid,
    };
    await this.fetchQuestions(filter);
  };

  fetchQuestions = async (filter) => {
    const { setNotification } = this.props;
    const filterByExam = { exam_id: filter.exam_id };
    const filterByUser = { creator_id: filter.creator_id };
    const questions = await getQuestions(filterByExam);
    const questionsFromBank = await getUserQuestions(filterByUser);
    if (questions.message) {
      setNotification({
        variant: "error",
        message: "Bu sınav için daha soru belirlenmedi",
      });
    } else {
      this.setState({ questions, questionsFromBank });
    }
  };

  renderQuestionModal = () => {
    return (
      <QuestionModal
        onClose={() => {
          this.setState({ questionModal: false });
          this.getQuestions();
        }}
        exam_id={this.props.match.params.id}
      />
    );
  };

  handleSave = async () => {
    const { exam, questions } = this.state;
    const { setNotification, history } = this.props;
    const response = await saveExam(exam);
    setNotification(response);
    if (response.variant === "success") {
      history.push("/userCreatedExams");
    }
  };

  handleSaveQuestionBank = async (addedQuestions) => {
    const { questionBank } = this.state;
    this.setState({ questionBank: !questionBank });
    const exam_id = this.props.match.params.id;
    for (const [key, value] of Object.entries(addedQuestions)) {
      value.answer !== undefined && delete value.answer;
    }
    await saveQuestionsToExamFromQuestionBank(addedQuestions, exam_id);
    this.getQuestions();
  };

  handleSaveQuestionTitleAndOrder = async (question) => {
    await saveQuestionTitleAndOrder(question, this.exam_questions_id);
    this.getQuestions();
  };

  renderSaveButtons = () => (
    <Fab
      variant="extended"
      onClick={this.handleSave}
      color="secondary"
      style={{ position: "fixed", bottom: 20, right: 70 }}
    >
      <SaveIcon />
      SINAV BİLGİLERİNİ KAYDET
    </Fab>
  );

  onAddNewQuestionButtonClick = () => {
    this.setState({ questionModal: true });
  };

  renderAddNewQuestionButton = () => (
    <Button
      variant="contained"
      color="primary"
      onClick={this.onAddNewQuestionButtonClick}
    >
      Yeni Soru Ekle
    </Button>
  );

  renderQuestionBank = () => {
    const { questionBank } = this.state;
    this.setState({ questionBank: !questionBank });
  };

  renderAddQuestionsFromQuestionBankButton = () => {
    const id = this.props.match.params.id;
    return (
      <Button
        variant="contained"
        color="primary"
        onClick={this.renderQuestionBank}
      >
        Soru Bankasından Soru Ekle
      </Button>
    );
  };

  handleCancelAddQuestionFromBank = () => {
    this.setState({ questionBank: false });
  };

  handleReadExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((rows) => {
      console.log(rows);

      addParticipantsToExam(
        this.state.exam.id,
        this.exam_questions_id,
        rows,
        (p) => {
          this.setState({ importedParticipantPercentage: p });
        }
      );
    });
  };

  renderInputs = () => {
    const {
      exam,
      questions,
      questionBank,
      questionsFromBank,
      importedParticipantPercentage,

      display_finish_button,
    } = this.state;
    let examCopy = { ...exam };
    const examDescription = (
      <TextField
        variant="outlined"
        value={exam && exam.description}
        multiline
        onChange={(e) => {
          const newVal = e.target.value;
          examCopy.description = newVal;
          this.setState({ exam: examCopy });
        }}
        fullWidth={true}
        size={"small"}
      />
    );

    const examExplanationAfterEnd = (
      <TextField
        variant="outlined"
        value={exam && exam.explanation_after_end}
        multiline
        onChange={(e) => {
          const newVal = e.target.value;
          examCopy.explanation_after_end = newVal;
          this.setState({ exam: examCopy });
        }}
        fullWidth={true}
        size={"small"}
      />
    );

    const examExplanationBeforeStart = (
      <TextField
        variant="outlined"
        value={exam && exam.explanation_before_start}
        multiline
        onChange={(e) => {
          const newVal = e.target.value;
          examCopy.explanation_before_start = newVal;
          this.setState({ exam: examCopy });
        }}
        fullWidth={true}
        size={"small"}
      />
    );

    const examExplanationDuringExam = (
      <TextField
        variant="outlined"
        value={exam?.explanation_during_exam}
        multiline
        onChange={(e) => {
          const newVal = e.target.value;
          examCopy.explanation_during_exam = newVal;
          this.setState({ exam: examCopy });
        }}
        fullWidth={true}
        size={"small"}
      />
    );

    const examHeader = (
      <TextField
        variant="outlined"
        value={exam && exam.header}
        multiline
        onChange={(e) => {
          const newVal = e.target.value;
          examCopy.header = newVal;
          this.setState({ exam: examCopy });
        }}
        fullWidth={true}
        size={"small"}
      />
    );

    const exam_start_date = exam?.start_date?.toString();
    const examStartDate = (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid container justifyContent="space-around">
          <DesktopDatePicker
            label="Tarih"
            // format="dd.MM.yyyy"
            inputFormat="dd.MM.yyyy"
            value={exam_start_date}
            minDate={new Date("2022-01-01")}
            onChange={(date) => {
              examCopy.start_date = date;
              this.setState({
                exam: examCopy,
              });
            }}
            renderInput={(params) => <TextField {...params} />}
          />
          <TimePicker
            label="Saat"
            value={exam_start_date}
            onChange={(date) => {
              examCopy.start_date = date;
              this.setState({
                exam: examCopy,
              });
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
      </LocalizationProvider>
    );

    const exam_end_date = exam?.end_date?.toString();
    const examEndDate = (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid container justifyContent="space-around">
          <DesktopDatePicker
            label="Tarih"
            // format="dd.MM.yyyy"
            inputFormat="dd.MM.yyyy"
            value={exam_end_date}
            minDate={new Date("2022-01-01")}
            onChange={(date) => {
              examCopy.end_date = date;
              this.setState({
                exam: examCopy,
              });
            }}
            renderInput={(params) => <TextField {...params} />}
          />
          <TimePicker
            label="Saat"
            value={exam_end_date}
            onChange={(date) => {
              examCopy.end_date = date;
              this.setState({
                exam: examCopy,
              });
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
      </LocalizationProvider>
    );

    const examDuration = (
      <TextField
        variant="outlined"
        value={exam?.duration}
        onChange={(e) => {
          const newVal = e.target.value;
          examCopy.duration = parseInt(newVal);
          this.setState({ exam: examCopy });
        }}
        fullWidth={true}
        size={"small"}
        type="number"
      />
    );

    const examCost = (
      <TextField
        variant="outlined"
        value={exam?.exam_cost}
        onChange={(e) => {
          const newVal = e.target.value;
          examCopy.exam_cost = parseInt(newVal);
          this.setState({ exam: examCopy });
        }}
        fullWidth={true}
        size={"small"}
        type="number"
      />
    );

    const addExamParticipants = (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {importedParticipantPercentage < 100 && (
          <CircularProgressWithLabel value={importedParticipantPercentage} />
        )}
        {importedParticipantPercentage === 100 && (
          <input
            type="file"
            onChange={(e) => {
              const file = e.target.files[0];
              // eslint-disable-next-line no-undef
              this.handleReadExcel(file);
            }}
          />
        )}

        <Indir />
      </div>
    );

    const examQuestions = questions && questions.length > 0 && (
      <div
        style={{
          backgroundColor: "#dbdad6",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {questions.map((question, question_index) => (
          <QuestionCard
            key={question.id}
            question={question}
            question_index={question_index}
            getQuestions={this.getQuestions}
            examId={exam.id}
            editExam={true}
            handleSaveQuestionTitleAndOrder={
              this.handleSaveQuestionTitleAndOrder
            }
            // examStatus={exam.status}
          />
        ))}
      </div>
    );

    const examQuestionBank = (
      <div style={{ backgroundColor: "#dbdad6" }}>
        <AddQuestionToExam
          questionsFromBank={questionsFromBank}
          examQuestions={questions}
          handleSaveQuestionBank={this.handleSaveQuestionBank}
          Cancel={this.handleCancelAddQuestionFromBank}
        />
      </div>
    );

    const examStatus = (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <RadioGroup
          row
          onChange={(event) => {
            const newVal = event.target.value;

            const status = parseInt(newVal);
            examCopy.status = status;
            this.setState({ exam: examCopy });
          }}
          // value={this.state.categoryOption}
        >
          <FormControlLabel
            value="1"
            control={<Radio checked={exam.status === 1 && true} />}
            label="Başlamadı"
          />
          <FormControlLabel
            value="2"
            control={<Radio checked={exam.status === 2 && true} />}
            label="Devam Ediyor"
          />
          <FormControlLabel
            value="3"
            control={<Radio checked={exam.status === 3 && true} />}
            label="Süresi Bitti"
          />
        </RadioGroup>
      </div>
    );

    const examHideQuestions = (
      <div>
        <Switch
          checked={examCopy.hide_questions_after_finish}
          onChange={(e) => {
            const newVal = e.target.checked;
            examCopy.hide_questions_after_finish = newVal;
            this.setState({ exam: examCopy });
          }}
          name="hideSwitch"
        />
        <span>
          {examCopy.hide_questions_after_finish
            ? "Sınav süresi dolduğunda sorular gizlenecek."
            : "Sınav süresi dolduğunda sorular gösterilmeye devam edecek (ama cevap güncellenemez.)"}
        </span>
      </div>
    );

    const finishExamButtonSwitch = (
      <div>
        <Switch
          checked={examCopy.display_finish_exam_button}
          onChange={(e) => {
            const newVal = e.target.checked;
            examCopy.display_finish_exam_button = newVal;
            this.setState({ exam: examCopy});
          }}
          name="finishExamButtonSwitch"
        />
        <span>
          {examCopy.display_finish_exam_button
            ? "'Sınavı Bitir' butonu gösterilecek. Öğrenci butona bastıktan sonra halen süresi kalsa bile cevapları güncelleyemez."
            : "'Sınavı Bitir' butonu gösterilemeyecek. Sınav süresi dolana kadar öğrenci istediği zaman cevaplarını güncelleyebilir."}
        </span>
      </div>
    );

    const lastResponseUpdatedAt = (
      <div>
        <Switch
          checked={examCopy.show_last_response_time_on_screen}
          onChange={(e) => {
            const newVal = e.target.checked;
            examCopy.show_last_response_time_on_screen = newVal;
            this.setState({ exam: examCopy});
          }}
          name="lastResponseUpdatedAt"
        />
        <span>
          {examCopy.show_last_response_time_on_screen
            ? "'Son Cevap Güncelleme Tarihi' gösterilecek. Öğrenciler son cevap güncelledikleri tarihi görebilecek ."
            : "'Son Cevap Güncelleme Tarihi' gösterilmeyecek. Öğrenciler son cevap güncelledikleri tarihi göremeyecek."}
        </span>
      </div>
    );

    const showRespondersName = (
      <div>
        <Switch
          checked={examCopy.show_responders_name}
          onChange={(e) => {
            const newVal = e.target.checked;
            examCopy.show_responders_name = newVal;
            this.setState({ exam: examCopy });
          }}
          name="showRespondersName"
        />
        <span>
          {examCopy.show_responders_name
            ? "Takım halinde yapılan sınavlarda, cevabı güncelleyen kişinin ismi, cevabın yanında yazacak"
            : "Bireysel sınavlar için cevap güncelleyen kişinin ismi yazmayacak."}
        </span>
      </div>
    );


    const examAddNewQuestionButton = this.renderAddNewQuestionButton();

    const examAddQuestionsFromQuestionBankButton =
      this.renderAddQuestionsFromQuestionBankButton();

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TableContainer
            component={Paper}
            style={{ backgroundColor: "#dbdad6" }}
          >
            <Table aria-label="simple table">
              <TableBody>
                <DataRow label={"Sınav Başlığı"} inputs={examHeader} />
                <DataRow label={"Sınav Alt Başlığı"} inputs={examDescription} />
                <DataRow
                  label={"Sınav Başlama Öncesi Açıklama"}
                  inputs={examExplanationBeforeStart}
                />
                <DataRow
                  label={"Sınav Sırasında Gösterilecek Açıklama"}
                  inputs={examExplanationDuringExam}
                />
                <DataRow
                  label={"Sınav Bittikten Sonraki Açıklama"}
                  inputs={examExplanationAfterEnd}
                />
                <DataRow
                  label={"Sınav Süresi"}
                  inputs={examDuration}
                  title="Örneğin sınav için 90 dakika süre yazılmışsa, kullanıcı BAŞLA butonuna bastıktan 90 dakika sonra cevap giremez. Sınav hala açık olsa bile."
                />
                <DataRow
                  label={"Sınav Ücreti"}
                  inputs={examCost}
                  title="TL cinsinden ücreti yazabilirsiniz. Boş bırakılmasında sakınca yoktur."
                />
                <DataRow
                  label={"Excel Dosyasından Katılımcı Ekle"}
                  inputs={addExamParticipants}
                  title="Bu özellik Kanguru için eklenmiştir."
                />
                <DataRow
                  title="Bilgi amaçlı bu kısmı doldurabilirsiniz. Henüz bir fonksiyonu yoktur. İleride bu tarih/saate göre sınav otomatik açılıp kapanacaktır."
                  label={"Sınav Başlangıç Tarihi"}
                  inputs={examStartDate}
                />
                <DataRow
                  title="Bilgi amaçlı bu kısmı doldurabilirsiniz. Henüz bir fonksiyonu yoktur. İleride bu tarih/saate göre sınav otomatik açılıp kapanacaktır."
                  label={"Sınav Bitiş Tarihi"}
                  inputs={examEndDate}
                />
                <DataRow
                  label={"Sınav Durumu"}
                  inputs={examStatus}
                  title="Bu kısım en önemlisi! Kapalı bir sınavı yanlışlıka 'Devam Ediyor' durumuna getirirseniz kullanıcılar cevap güncellemeye devam edebilir."
                />
                <DataRow
                  label={"Sınav Bitiminde Soruları Göster/Gizle"}
                  inputs={examHideQuestions}
                />
                <DataRow
                  label={"'Sınavı Bitir' Butonu Gizle/Göster"}
                  inputs={finishExamButtonSwitch}
                />
                <DataRow
                  label={"'Son Cevap Güncelleme Tarihi' Gizle/Göster"}
                  inputs={lastResponseUpdatedAt}
                />
                <DataRow
                  label={"Cevap güncelleyen takım üyesinin ismini Gizle/Göster"}
                  inputs={showRespondersName}
                />
                <DataRow
                  // label={examAddNewQuestionButton}
                  // inputs={examAddQuestionsFromQuestionBankButton}
                  inputs={
                    <>
                      {examAddNewQuestionButton}
                      {examAddQuestionsFromQuestionBankButton}
                    </>
                  }
                />
              </TableBody>
            </Table>
            {/* {this.renderAddNewQuestionButton()}
            {this.renderAddQuestionsFromQuestionBankButton()} */}
            <h2 style={{ textAlign: "center" }}>{"Sorular"}</h2>
            {questionBank ? examQuestionBank : examQuestions}
          </TableContainer>

          {this.state.questionModal && this.renderQuestionModal()}
        </Grid>
        {this.renderSaveButtons()}
      </Grid>
    );
  };

  render() {
    const { user } = this.props;
    const content = <div> {this.renderInputs()}</div>;
    return <div>{user && <HomeTemplate content={content} />}</div>;
  }
}

const DataRow = (props) => {
  return (
    <TableRow style={{ ...props.style }}>
      <TableCell
        component="th"
        scope="row"
        style={{ padding: "5px", width: 270 }}
        title={props.title}
      >
        {props.label}
      </TableCell>
      <TableCell align="left" style={{ padding: "5px" }}>
        {props.inputs}{" "}
      </TableCell>
    </TableRow>
  );
};

const mapStateToProps = (state, props) => ({
  user: state.auth.user,
  additionalInfo: state.auth.additionalInfo,
  stateExam: state.exams.createdExamList.data.find(
    (e) => e.id === props.match.params.id
  ),
  questionList: state.exams.questionList.data,
});

const mapDispatchToProps = (dispatch) => {
  return {
    // fetchExam: (exam_id) => dispatch(getExam(exam_id)),
    // examQuestions: (filter) => dispatch(getQuestionList(filter)),
    setNotification: (notification) => dispatch(setNotification(notification)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateAndEditExamPage);
