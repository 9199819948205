import React, { Component } from "react";
import { connect } from "react-redux";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export class QuestionChoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
    };
  }

  componentDidMount() {
    const { examQuestions, question } = this.props;
    for (let i = 0; i < examQuestions.length; i++) {
      if (examQuestions[i].id === question.id) {
        this.setState({ checked: true });
      }
    }
  }

  onCheckboxChange = () => {
    const { checked } = this.state;
    const { addAndRemoveQuestion, question, question_index } = this.props;
    this.setState(
      { checked: !checked },
      addAndRemoveQuestion(question, question_index, !checked)
    );
  };

  render() {
    const { checked } = this.state;
    const { question, question_index } = this.props;
    return (
      <div key={question_index}>
        <FormControl component="fieldset">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={() => {
                    this.onCheckboxChange();
                  }}
                  name={question.id}
                />
              }
              label={question.name && question.name}
            />
          </FormGroup>
        </FormControl>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionChoice);
