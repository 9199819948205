import React, { Component } from "react";
import { connect } from "react-redux";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import MaterialButton from "@mui/material/Button";
import { TextField } from "@mui/material";
import QuestionModal from "./questionModal";
import EditIcon from "@mui/icons-material/Edit";
import {
  saveAnswerOfQuestion,
  deleteQuestionFromExam,
} from "../../../db/examMethods";
import { setNotification } from "../../../store/slices/examSlice";
import MaterialBackDrop from "../../molecules/materialBackDrop/materialBackDrop";
import "./questions.css";

const qu_box_style = {
  textAlign: "left",
  padding: 30,
  margin: 10,
  borderRadius: 15,
  maxWidth: 700,
  backgroundColor: "white",
  width: "100%",
  flexDirection: "column",
  alignItems: "flex-start",
  color: "black",
  position: "relative",
  fontSize: "0.9rem",
  display: "flex",
  pageBreakInside: "avoid",
  // pageBreakAfter:'inherit',
  pageBreakBefore: "auto",
};

const true_qu_box_style = {
  ...qu_box_style,
  backgroundColor: "#c1e9c5",
};

export class QuestionCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionModal: false,
      editIconVisible: false,
      fadeOut: false,
      userAnswer: {},
      isLoading: false,
      times_up: false,
      question_title: props.question.title ?? "",
      question_order: props.question.order ?? 0,
      question_score: props.question.question_score ?? "",
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.examStatus === 2 && this.props.examStatus === 3) {
      this.setState({ userAnswer: {} });
    }
  }

  onEditClicked = () => {
    this.setState({ questionModal: true });
  };

  renderQuestionModal = (question, question_index) => {
    return (
      <QuestionModal
        edit
        question={question}
        question_index={question_index}
        onClose={() => {
          this.setState({ questionModal: false });
          this.props.getQuestions();
        }}
      />
    );
  };

  renderMultipleChoiceAnswer = (key, question, question_index) => {
    const {
      form,
      inExamPage,
      responseOnForm,
      inQuizPage,
      saveQuizAnswer,
      showRespodersName,
    } = this.props;
    const userAnswer = this.state.userAnswer;

    const isThisAnswerSelected =
      responseOnForm && responseOnForm.response === key;
    // const responderName = responseOnForm && responseOnForm.responder_name;

    const responderName = isThisAnswerSelected && showRespodersName && (
      <span>
        {"(Cevabı seçen yarışmacı: " + responseOnForm.responder_name + ")"}
      </span>
    );

    const radioAndKey = (
      <div
        style={{
          display: "flex",
          marginRight: 4,
          alignItems: "center",
        }}
      >
        <input
          // disabled={announcement_date_has_come}
          type="radio"
          checked={isThisAnswerSelected === true}
          style={{
            marginRight: 10,
            width: 20,
            height: 20,
          }}
          onChange={() => {
            if (inExamPage) {
              if (inQuizPage) saveQuizAnswer(key);
              else this.saveResponse(key);
            }
          }}
        ></input>
        {key + ")"}
      </div>
    );

    const choiceTextDiv = <div>{question.choices[key].choice_text}</div>;

    const choiceImageDiv = question.choices[key].choice_image && (
      <img
        alt="-"
        style={{ maxWidth: 500, width: "100%" }}
        src={question.choices[key].choice_image}
      />
    );

    return (
      <div
        key={question_index + "_" + key}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          border: isThisAnswerSelected ? "solid red 2px" : "solid #aba5a5 1px",
          borderRadius: 10,
          padding: 5,
          marginBottom: 5,
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {radioAndKey}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {choiceTextDiv}
            {choiceImageDiv}
          </div>
        </div>

        {responderName}
      </div>
    );
  };

  onTextAnswerChanged = (e) => {
    const { setNotification, question } = this.props;
    const newVal = e.target.value.toLocaleUpperCase("tr-TR");
    const newValLastCharacter = newVal.charAt(newVal.length - 1);
    const permittedCharacters = question.permitted_characters_for_response;

    if (question.type === 3 && isNaN(newVal)) {
      setNotification({
        variant: "error",
        message: "Bu soru için sadece sayısal bir cevap yazabilirsiniz.",
      });
      return;
    } else if (
      question.type === 2 &&
      permittedCharacters &&
      !(permittedCharacters.indexOf(newValLastCharacter) > -1)
    ) {
      setNotification({
        variant: "error",
        message:
          "Bu soru için sadece " +
          permittedCharacters +
          " karakterlerini kullanarak cevap girebilirsiniz!",
      });
      return;
    } else {
      this.setState({
        userAnswer: { ...this.state.userAnswer, response: newVal },
      });
    }
  };

  onSaveButtonClicked = (question) => {
    const { inExamPage, inQuizPage, saveQuizAnswer, setNotification } =
      this.props;

    const userAnswer = this.state.userAnswer;
    const savedResponse = userAnswer && userAnswer.response;
    const minPermittedCharacterLength = question.min_permitted_char_length ?? 0;
    const maxPermittedCharacterLength =
      question.max_permitted_char_length ?? 999999999;

    if (!userAnswer.response) {
      setNotification({
        variant: "error",
        message: "Lütfen bir cevap giriniz.",
      });
      return;
    }

    if (
      question.type === 2 &&
      userAnswer.response?.length < minPermittedCharacterLength
    ) {
      setNotification({
        variant: "error",
        message:
          "Bu soru için minimum " +
          minPermittedCharacterLength +
          " karakter kullanarak cevap girebilirsiniz.",
      });
      return;
    }

    if (
      question.type === 2 &&
      userAnswer.response.length > maxPermittedCharacterLength
    ) {
      setNotification({
        variant: "error",
        message:
          "Bu soru için maksimum " +
          maxPermittedCharacterLength +
          " karakter kullanarak cevap girebilirsiniz.",
      });
      return;
    }

    if (inExamPage) {
      if (inQuizPage) {
        const response = savedResponse ? savedResponse.trim() : "";
        saveQuizAnswer(response);
      } else this.saveResponse(savedResponse);
    }
  };

  saveResponse = async (resp) => {
    const { setNotification, showRespodersName } = this.props;
    if (resp) {
      const { examId, question, user, linkedUserId, linkedUserName } =
        this.props;

      const params = {
        examId: examId,
        userId: linkedUserId ? linkedUserId : user.uid,
        questionTitle: question.title,
        userName: linkedUserName ? linkedUserName : user.name ? user.name : "",
        questionId: question.id,
        userAnswer: resp.trim(),
      };

      this.setState({
        isLoading: true,
      });

      const res = await saveAnswerOfQuestion(params);
      if (res.message === "Sınav süresi dolduğu için cevap kaydedilemedi.") {
        this.props.disableAllQuestions();
      }
      this.setState({ isLoading: false, userAnswer: {} });
      resp === " "
        ? !showRespodersName &&
          setNotification({
            variant: "success",
            message: "Cevap başarıyla silindi",
          })
        : !showRespodersName && setNotification(res);
    }
  };

  renderTextInputAnswer = (question, question_index) => {
    const announcement_date_has_come = false;
    const { responseOnForm, inExamPage, inQuizPage, showRespodersName } =
      this.props;

    const userAnswer = this.state.userAnswer;

    const response_text = responseOnForm && responseOnForm.response;
    const responderName = responseOnForm && responseOnForm.responder_name;
    return (
      <div key={question_index + "_question_with_text_field"}>
        {!inQuizPage && (
          <div
            style={{
              padding: 5,
              color: "#b90213",
              fontWeight: "bold",
              fontSize: 15,
            }}
          >
            {response_text && response_text !== " "
              ? "Kayıtlı cevabınız: " + response_text
              : announcement_date_has_come
              ? "Bu soru için cevap kaydedilmemiştir."
              : "Bu soru için henüz cevap kaydedilmemiştir."}
          </div>
        )}
        {responderName && showRespodersName && response_text !== "" && (
          <p>(Bu cevap {responderName} tarafından kaydedilmiştir.)</p>
        )}

        {responderName && showRespodersName && response_text === "" && (
          <p>(Cevap {responderName} tarafından silinmiştir.)</p>
        )}
        {/* {!announcement_date_has_come && (
          
        )} */}
        {inExamPage && (
          <>
            <TextField
              variant="outlined"
              color="secondary"
              //   defaultValue={savedResponse}
              value={
                (userAnswer &&
                  userAnswer.response !== " " &&
                  userAnswer.response) ||
                ""
              }
              onChange={(e) => {
                if (inExamPage) this.onTextAnswerChanged(e);
              }}
              style={{ marginRight: 20, marginBottom: 10 }}
              size={"small"}
              placeholder="Cevap kutusu..."
            />

            {/* {!announcement_date_has_come && (
          
        )} */}
            {inExamPage && (
              <MaterialButton
                variant="contained"
                color="primary"
                //className={classes.button}
                startIcon={<SaveIcon />}
                onClick={() => this.onSaveButtonClicked(question)}
              >
                {response_text ? "CEVABI DEĞİŞTİR" : "CEVABI KAYDET"}
              </MaterialButton>
            )}
          </>
        )}
      </div>
    );
  };

  saveQuestionTitleAndOrder = (question) => {
    const { question_title, question_order, question_score } = this.state;
    const { handleSaveQuestionTitleAndOrder } = this.props;

    let questionObject = {
      ...question,
      title: question_title,
      order: question_order, //TODO: Burası order'ı string olarak kaydediyor. Bunu değiştir.
      question_score,
    };
    handleSaveQuestionTitleAndOrder(questionObject);
  };

  renderChangeTitleAndOrder = (question, question_index) => {
    const { question_title, question_order, question_score } = this.state;
    const { inExamPage, inQuizPage, editExam } = this.props;

    return (
      <div key={question_index + "_question_with_text_field"}>
        {editExam && !inExamPage && !inQuizPage && (
          <>
            <TextField
              variant="outlined"
              color="secondary"
              value={(question_title && question_title) || ""}
              onChange={(e) => {
                const newVal = e.target.value;
                this.setState({ question_title: newVal });
              }}
              style={{ marginRight: 20, marginBottom: 10 }}
              size={"small"}
              label="Buklet Üzerindeki Soru Başlığı"
            />{" "}
            <TextField
              variant="outlined"
              color="secondary"
              value={(question_order && question_order) || ""}
              onChange={(e) => {
                const newVal = e.target.value;
                this.setState({ question_order: newVal });
              }}
              style={{ marginRight: 20, marginBottom: 10, width: 85 }}
              size={"small"}
              label="Sırası"
            />
            <TextField
              variant="outlined"
              color="secondary"
              value={question_score ?? ""}
              type="number"
              onChange={(e) => {
                const newVal = e.target.value;
                this.setState({ question_score: parseInt(newVal) });
              }}
              style={{ marginRight: 20, marginBottom: 10, width: 90 }}
              size={"small"}
              label="Puanı"
            />
            <MaterialButton
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={() => {
                this.saveQuestionTitleAndOrder(question);
              }}
            >
              {question_title !== "" ? "Güncelle" : "Kaydet"}
            </MaterialButton>
          </>
        )}
      </div>
    );
  };

  renderBlock = () => {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          zIndex: "999",
          borderRadius: 15,
          backgroundColor: "#62595963",
          top: 0,
          left: 0,
        }}
      ></div>
    );
  };

  handleDeleteQuestion = async () => {
    const { question, question_index, getQuestions, examId } = this.props;
    await deleteQuestionFromExam(question.id, examId);
    getQuestions();
  };

  renderQuestion = (question, question_index) => {
    const {
      inExamPage,
      examStatus,
      fromModal,
      responseOnForm,
      inQuizPage,
      editExam,
    } = this.props;

    let isUserResponseCorrect =
      question.answer !== undefined &&
      question.answer.split("&").includes(responseOnForm?.response);

    //Kanguru'da, iptal olup da herkese tam puan verilecek sorulara negatif puan giriliyor.

    let originalQuesitonScore = question?.question_score || 0;
    let fixedQuestionScore = originalQuesitonScore.toFixed(3);
    if (fixedQuestionScore == originalQuesitonScore)
      // Dikkat, burası == olacak. === değil!
      fixedQuestionScore = originalQuesitonScore;

    // Negatif paunlı sorular Kanguru için özel olarak ekleniyor.
    //Bu sorular aslında iptal ediliyor, ama tüm öğrencilere tam puan veriliyor.
    if (fixedQuestionScore < 0) {
      isUserResponseCorrect = true;
      fixedQuestionScore *= -1;
    }

    return (
      <div
        key={question.id}
        style={isUserResponseCorrect ? true_qu_box_style : qu_box_style}
      >
        {(examStatus !== 2 || this.state.times_up === true) &&
          inExamPage &&
          this.renderBlock()}
        {this.state.isLoading && <MaterialBackDrop />}
        {!inExamPage && !fromModal && (
          <EditIcon
            onClick={this.onEditClicked}
            style={{
              width: "30px",
              height: "30px",
              backgroundColor: "#FFAB91",
              borderRadius: "999px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              position: "absolute",
              top: "0",
              left: "0",
              zIndex: 3,
              fontSize: "3px",
            }}
          />
        )}
        {!inExamPage && !fromModal && editExam && (
          <div
            onClick={() => {
              if (
                window.confirm(
                  "Soruyu sınavdan kaldırmak istediğinize emin misiniz?"
                )
              ) {
                this.handleDeleteQuestion();
              }
            }}
            style={{
              width: "30px",
              height: "30px",
              backgroundColor: "#FFAB91",
              borderRadius: "999px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              position: "absolute",
              top: "0",
              right: "0",
              zIndex: 3,
            }}
          >
            <DeleteIcon />
          </div>
        )}

        {!inQuizPage && inExamPage && (
          <span
            style={{
              fontWeight: "bold",
              backgroundColor: "darkorange",
              padding: "3px 8px 3px 8px",
              borderRadius: 7,
              marginBottom: 15,
            }}
          >
            {question.title
              ? question.question_category //TODO: question_category özelliği sistemden tamamen silinecek. Oyun 2022 finalinden sonra bu özelliği kaldırıp bunun yerine sub_category kullanmak gerekecek.
                ? question.title + " " + question.question_category
                : question.title
              : `Soru ${question_index + 1}`}
          </span>
        )}
        {!inExamPage && (
          <div className="questionInfoBox">
            <div className="questionInfoLabel">id</div>
            <span>{question.id}</span>
          </div>
        )}
        {!inExamPage && question.name && (
          <div className="questionInfoBox">
            <div className="questionInfoLabel">Soru adı</div>
            <span>{question.name}</span>
          </div>
        )}

        {!inExamPage && (
          <div className="questionInfoBox" style={{ marginBottom: 12 }}>
            <div className="questionInfoLabel">Kategori</div>
            {question.category && (
              <span>{question.category + " > " + question.sub_category}</span>
            )}
          </div>
        )}

        {editExam &&
          !inExamPage &&
          !inQuizPage &&
          this.renderChangeTitleAndOrder(question, question_index)}
        {question.question_header_text && (
          <span style={{ whiteSpace: "pre-wrap", marginBottom: 15 }}>
            {question.question_header_text}
          </span>
        )}
        {question.question_image_path && (
          <img
            style={{
              alignSelf: "center",
              maxWidth: 640,
              width: "90vw",
              marginBottom: 20,
              borderRadius: 14,
            }}
            src={question.question_image_path}
            alt="ill-pair"
          />
        )}
        {question.question_text && (
          <span style={{ whiteSpace: "pre-wrap", marginBottom: 15 }}>
            {question.question_text}
          </span>
        )}

        {(!question.choice_columns || question.choice_columns === 1) && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {question.type && question.type === 1 && question.choices
              ? Object.keys(question.choices)
                  .sort((c1, c2) => c1.localeCompare(c2))
                  .map((key) =>
                    this.renderMultipleChoiceAnswer(
                      key,
                      question,
                      question_index
                    )
                  )
              : question.type === 2 || question.type === 3
              ? this.renderTextInputAnswer(question, question_index)
              : ""}
          </div>
        )}

        {question.choice_columns === 2 && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: 5,
                flex: 1,
              }}
            >
              {question.type && question.type === 1 && question.choices
                ? Object.keys(question.choices)
                    .sort((c1, c2) => c1.localeCompare(c2))
                    .map((key, index) => {
                      if (index % 2 === 1) return null;
                      return this.renderMultipleChoiceAnswer(
                        key,
                        question,
                        question_index
                      );
                    })
                : question.type === 2 || question.type === 3
                ? this.renderTextInputAnswer(question, question_index)
                : ""}
            </div>

            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              {question.type && question.type === 1 && question.choices
                ? Object.keys(question.choices)
                    .sort((c1, c2) => c1.localeCompare(c2))
                    .map((key, index) => {
                      if (index % 2 === 0) return null;
                      return this.renderMultipleChoiceAnswer(
                        key,
                        question,
                        question_index
                      );
                    })
                : question.type === 2 || question.type === 3
                ? this.renderTextInputAnswer(question, question_index)
                : ""}
            </div>
          </div>
        )}

        {!inQuizPage && (
          <div
            style={{
              padding: 5,
              color: "#b90213",
              fontWeight: "bold",
              fontSize: 15,
            }}
          >
            {question.answer &&
              "Doğru cevap: " + question.answer.replaceAll("&", ", ")}
          </div>
        )}
        {!inQuizPage && (
          <div
            style={{
              padding: 5,
              color: "#b90213",
              fontWeight: "bold",
              fontSize: 15,
            }}
          >
            {question.question_score && "Sorunun Puanı: " + fixedQuestionScore}
          </div>
        )}
        <div>
          {!inQuizPage &&
            inExamPage &&
            responseOnForm &&
            responseOnForm.response &&
            responseOnForm.response !== " " && (
              <MaterialButton
                variant="contained"
                color="secondary"
                onClick={() => {
                  if (
                    window.confirm("Cevabı silmek istediğinize emin misiniz?")
                  ) {
                    const resp = " ";
                    this.saveResponse(resp);
                  }
                }}
              >
                {"KAYITLI CEVABI SİL"}
              </MaterialButton>
            )}
        </div>
        {!inExamPage &&
          this.state.questionModal &&
          this.renderQuestionModal(question, question_index)}
      </div>
    );
  };

  render() {
    const { question, question_index } = this.props;
    return this.renderQuestion(question, question_index);
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setNotification: (notification) => dispatch(setNotification(notification)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(QuestionCard);
