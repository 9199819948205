import React, { Component } from "react";
import { connect } from "react-redux";
import { ExternalExamTemplate } from "../../components/templates/externalExamTemplate/externalExamTemplate";
import { HomeTemplate } from "../../components/templates/homeTemplate/homeTemplate";
import QuestionList from "./questionList";

export class QuestionBankPage extends Component {
  render() {
    return (
      <HomeTemplate
        content={
          <div style={{backgroundColor: "#dbdad6", display:"flex", flexDirection:"column", alignItems:"center"}}>
            <h1>SORU BANKASI</h1>
            <QuestionList />
          </div>
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionBankPage);
