import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchExamList,
  fetchQuizList,
  fetchExam,
  fetchCreatedExamList,
  getQuestions,
  getUserQuestions,
  fetchPublicData,
} from "../../db/examMethods";

export const getExamList = createAsyncThunk(
  "exam/getExamList",
  async (examinee_id) => {
    try {
      const exams = await fetchExamList(examinee_id);
      return exams.map((e) => ({
        id: e.id,
        description: e.description,
        header: e.header,
        status: e.status,
        explanation_after_end: e.explanation_after_end,
        explanation_before_start: e.explanation_before_start,
        start_date: e.start_date,
        end_date: e.end_date,
      }));
    } catch (error) {
      console.log("error from fetchExams -----> ", error);
    }
  }
);

export const getExam = createAsyncThunk("exam/getExam", async (exam_id) => {
  try {
    const exam = await fetchExam(exam_id);
    return exam;
  } catch (error) {
    console.log("error from fetchExams -----> ", error);
  }
});

export const getCreatedExamList = createAsyncThunk(
  "exam/getCreatedExamList",
  async (creator_id) => {
    try {
      const exams = await fetchCreatedExamList(creator_id);
      return exams.map((e) => ({
        id: e.id,
        description: e.description,
        header: e.header,
        status: e.status,
        explanation_after_end: e.explanation_after_end,
        explanation_before_start: e.explanation_before_start,
        // start_date: e.start_date.toDate(),
        // end_date: e.end_date.toDate(),
        start_date: e.start_date,
        end_date: e.end_date,
      }));
    } catch (error) {
      console.log("error from fetchCreatedExams -----> ", error);
    }
  }
);

export const getQuizList = createAsyncThunk(
  "exam/getQuizList",
  async (type) => {
    try {
      const quizes = await fetchQuizList(type);
      return quizes.map((e) => ({
        id: e.id,
        description: e.description,
        header: e.header,
        status: e.status,
        explanation_after_end: e.explanation_after_end,
        explanation_before_start: e.explanation_before_start,
      }));
    } catch (error) {
      console.log("error from fetchExams -----> ", error);
    }
  }
);

export const getQuestionList = createAsyncThunk(
  "exam/getQuestionList",
  async (filter) => {
    try {
      const questions = await getQuestions(filter);
      return questions;
      // return exams.map((e) => ({
      //   id: e.id,
      //   description: e.description,
      //   header: e.header,
      //   is_accessable: e.is_accessable,
      // }));
    } catch (error) {
      console.log("error from getQuestions -----> ", error);
    }
  }
);

export const getUserQuestionList = createAsyncThunk(
  "exam/getQuestionList",
  async (filter) => {
    try {
      const questions = await getUserQuestions(filter);
      return questions;
      // return exams.map((e) => ({
      //   id: e.id,
      //   description: e.description,
      //   header: e.header,
      //   is_accessable: e.is_accessable,
      // }));
    } catch (error) {
      console.log("error from getQuestions -----> ", error);
    }
  }
);

export const getPublicData = createAsyncThunk(
  "exam/getPublicData",
  async () => {
    try {
      const res = await fetchPublicData();
      return res;
    } catch (error) {
      console.log("error from getPublicData -----> ", error);
    }
  }
);

export const examSlice = createSlice({
  name: "exams",
  initialState: {
    examList: { data: [] },
    getExam: {},
    // fetchedExam: {},
    createdExamList: { data: [] },
    quizList: { data: [] },
    questionList: { data: [] },
    userQuestionList: { data: [] },
    nextNotification: null,
    publicData: {home_page_exams:[]},
  },
  // asenkron bir işleme bağlı olmayan global state objelerini burada set edebilirsin.
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    setNotification(state, action) {
      state.nextNotification = action.payload;
    },
    insertExamToList(state, action){
      state.examList= {
        ...state.examList,
        data:[
          ...(state.examList.data?? []),
          action.payload
        ]
      }
    }
  },

  // asenkron fonksiyonların sonucuna göre set edilmesi gereken global state değişkenlerini burada güncelle.
  extraReducers: {
    [getExamList.fulfilled]: (state, action) => {
      //   state.exams = [...action.payload];
      state.examList = {
        status: "fulfilled",
        data: action.payload,
        error: null,
      };
    },
    [getExamList.pending]: (state, action) => {
      state.examList = {
        ...state.examList,
        status: "pending",
        error: null,
      };
    },
    [getExamList.rejected]: (state, action) => {
      state.examList = {
        ...state.examList,
        status: "rejected",
        error: action.payload,
      };
      state.nextNotification = {
        variant: "error",
        message: "HATA: Sınavlar çekilemedi!",
      };
    },

    [getExam.fulfilled]: (state, action) => {
      //   state.exams = [...action.payload];
      if (action.payload.message) {
        state.getExam = {
          status: "fulfilled",
          error: null,
        };
        state.nextNotification = {
          variant: "error",
          message: action.payload.message,
        };
      } else {
        state.createdExamList = {
          ...state.examList,
          data: [...state.examList.data, action.payload],
        };
        state.getExam = {
          status: "fulfilled",
          error: null,
        };
        state.nextNotification = {
          variant: "success",
          message: "Sınav başarılı bir şekilde çekildi.",
        };
      }
    },
    [getExam.pending]: (state, action) => {
      state.getExam = {
        status: "pending",
        error: null,
      };
    },
    [getExam.rejected]: (state, action) => {
      state.getExam = {
        status: "rejected",
        error: action.payload,
      };
      state.nextNotification = {
        variant: "error",
        message: "HATA: Sınav çekilemedi!",
      };
    },

    [getCreatedExamList.fulfilled]: (state, action) => {
      //   state.exams = [...action.payload];
      state.createdExamList = {
        status: "fulfilled",
        data: action.payload,
        error: null,
      };
    },
    [getCreatedExamList.pending]: (state, action) => {
      state.createdExamList = {
        ...state.createdExamList,
        status: "pending",
        error: null,
      };
    },
    [getCreatedExamList.rejected]: (state, action) => {
      state.createdExamList = {
        ...state.createdExamList,
        status: "rejected",
        error: action.payload,
      };
      state.nextNotification = {
        variant: "error",
        message: "HATA: Sınavlar çekilemedi!",
      };
    },

    [getQuizList.fulfilled]: (state, action) => {
      //   state.exams = [...action.payload];
      state.quizList = {
        status: "fulfilled",
        data: action.payload,
        error: null,
      };
    },
    [getQuizList.pending]: (state, action) => {
      state.quizList = {
        ...state.quizList,
        status: "pending",
        error: null,
      };
    },
    [getQuizList.rejected]: (state, action) => {
      state.quizList = {
        ...state.quizList,
        status: "rejected",
        error: action.payload,
      };
      state.nextNotification = {
        variant: "error",
        message: "HATA: Sınavlar çekilemedi!",
      };
    },

    [getQuestionList.fulfilled]: (state, action) => {
      state.questionList = {
        status: "fulfilled",
        data: action.payload,
        error: null,
      };
    },
    [getQuestionList.pending]: (state, action) => {
      state.questionList = {
        ...state.questionList,
        status: "pending",
        error: null,
      };
    },
    [getQuestionList.rejected]: (state, action) => {
      state.questionList = {
        ...state.questionList,
        status: "rejected",
        error: action.payload,
      };
      state.nextNotification = {
        variant: "error",
        message: "Sorular çekilirken bir hata meydana geldi.",
      };
    },

    [getUserQuestionList.fulfilled]: (state, action) => {
      state.userQuestionList = {
        status: "fulfilled",
        data: action.payload,
        error: null,
      };
    },
    [getUserQuestionList.pending]: (state, action) => {
      state.userQuestionList = {
        ...state.userQuestionList,
        status: "pending",
        error: null,
      };
    },
    [getUserQuestionList.rejected]: (state, action) => {
      state.userQuestionList = {
        ...state.userQuestionList,
        status: "rejected",
        error: action.payload,
      };
      state.nextNotification = {
        variant: "error",
        message: "Sorular çekilirken bir hata meydana geldi.",
      };
    },

    [getPublicData.fulfilled]: (state, action) => {
      state.publicData = {
        status: "fulfilled",
        ...action.payload,
        error: null,
      };
    },
    [getPublicData.pending]: (state, action) => {
      state.publicData = {
        ...state.publicData,
        status: "pending",
        error: null,
      };
    },
    [getPublicData.rejected]: (state, action) => {
      state.publicData = {
        ...state.publicData,
        status: "rejected",
        error: action.payload,
      };
      state.nextNotification = {
        variant: "error",
        message: "HATA: Ana sayfa verileri çekilemedi!",
      };
    },
  },
});
export const { setNotification, insertExamToList } = examSlice.actions;
export default examSlice.reducer;
