import { db } from "./firebaseConfig";

import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  setPersistence,
  browserSessionPersistence,
  sendPasswordResetEmail,
  sendEmailVerification,
} from "firebase/auth";
import {
  doc,
  setDoc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { WindowSharp } from "@mui/icons-material";

export const authUser = async (mail, pass) => {
  // firebase authentiocaton kullanarak kişinin o kişi olduğunu teyid ettikten sonra cloud sql'den kişiye ait detaylı bilgi çekeceğiz.
  let user = null;
  const auth = getAuth();
  let res = "OK";
  await signInWithEmailAndPassword(auth, mail, pass).catch((error) => {
    console.log("auth error------", error);
    if (error.code === "auth/wrong-password") {
      res = "Şifre hatalı.";
    } else if (error.code === "auth/user-not-found") {
      res = "Kullanıcı kaydı bulunamadı.";
    } else if (error.code === "auth/too-many-requests") {
      res =
        "Çok fazla şifre denemesi yapıldığı için 2 dakika boyunca giriş engellendi.";
    } else {
      res = "Bilinmeyen bir hata meydana geldi.";
    } // Hatalara saçma sapan kodlar veriyorum ki hata bildiren kullanıcılar ekran görüntüsü attığında doğrudan kaynağı bulalım :);
  });
  
  return res;
};

export const fetchUserDetails = async (uid) => {
  const docRef = doc(db, "users", uid);
  const docSnap = await getDoc(docRef);  
  if (docSnap.exists()) {

    return docSnap.data();

  } else {
    // doc.data() will be undefined in this case
    // veri yoksa {role:"examinee"} dönecek
    return { role: "examinee" };
  }
};

export const createProfile = async ({ email, name, pass }) => {
  const auth = getAuth();
  let user = {};
  let errorMessage = "";

  await createUserWithEmailAndPassword(auth, email, pass)
    .then((res) => {
      user = res.user;
      sendVerificationEmail(user);

    })
    .catch((error) => {
      if (error.code === "auth/email-already-in-use")
        errorMessage =
          email +
          " ile daha önce kayıt olunmuş. Lütfen oturum açma sayfasına gidiniz.";
      else
        errorMessage =
          "Hesap oluşturulurken bilinmeyen bir hata meydana geldi.";
    });
  const { uid } = user;
  console.log('errorMessage', errorMessage)
  
  if (uid) {
    const profile = {
      email,
      name,
      role: "examinee",
    };
    await setDoc(doc(db, "users", uid), profile).catch((e) => console.log(e));
    return { uid: user.uid, ...profile, ...user };
  }
  
  else return {error: errorMessage};

  
};

export const sendVerificationEmail = (usr) => {
  const options = {
    url: window.location.origin,
  };

  console.log("options", options);

  const userForVerify = usr ? usr : getAuth().currentUser;
  console.log("verification user:", userForVerify);
  sendEmailVerification(userForVerify, options).then(() => {
    // alert("Lütfen e-posta kutunuza giderek doğrulama linkine tıklayınız.");
  });
  // auth.user.sendEmailVerification().then(()=>{
  //   alert("Lütfen e-posta kutunuza giderek doğrulama linkine tıklayınız.");
  // });
  // sendEmailVerification(auth.user);
};

export const listenUserAuthState = (userSetter) => {
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    userSetter(user);
  });
};

export const fb_logout = () => {
  const auth = getAuth();
  signOut(auth).then(
    function () {
      // Sign-out successful.
    },
    function (error) {
      alert("Logut esnasında hata oldu: ", error);
    }
  );
};

export const requestPasswordReset = async (email) => {
  const auth = getAuth();
  return sendPasswordResetEmail(auth, email)
    .then(() => {
      return (
        email +
        " adresinize şifre sıfırlama linki gönderildi. Gelen kutunuzu kontrol ediniz."
      );
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log("errorCode:", errorCode);
      return "HATA: Sıfırlama maili gönderilemedi.";
    });
};

