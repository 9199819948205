import React, { Component } from "react";
import { connect } from "react-redux";
import { HomeTemplate } from "../../components/templates/homeTemplate/homeTemplate";
import { getQuestionList, setNotification } from "../../store/slices/examSlice";
import {
  listenExamById,
  listenAnswerForm,
  getQuestions,
  createAnswerFormAndStartExam,
  finishExam,
  getAnswerForms,
} from "../../db/examMethods";

import MaterialButton from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import QuestionCard from "../../components/organisms/questions/questionCard";
import { ExternalExamTemplate } from "../../components/templates/externalExamTemplate/externalExamTemplate";
import "./examPage.css";
import { createStringFromDate } from "../../common/commonMethods";
import { captureScreenshot } from "../../utils/takeScreenShot";
import { Timestamp } from "firebase/firestore";
/**
 Burası bizim sınav sayfamız. SİSTEMİN EN ÖNEMLİ SAYFALARINDA BİRİ. Şu anda işlevsel olarak çalışmakta ve bir çok sınavda
 denenmiştir. Sistemden Sınavın bilgilerini ve sınava ait exam questions bilgilerini çekip ekranda gösterilmesi ve yapılan
 her işlemin kaydedilmesini sağlayan yer.
 */
export class ExamPage extends Component {
  constructor(props) {
    super(props);
    this.examPageRef = React.createRef();
    this.unsubTimeout = {};
    this.state = {
      usersAnswer: {},
      exam: {},
      questions: [],
      form: {},
    };
  }
  componentDidMount() {
    this.listenExam();

    this.listenTeamAnswer();
  }

  componentDidUpdate(prevProps, prevState) {
    /* 
    Bu kısma çok uğraştık deneme yanılma yoluyla hallettik. Buraya bakan arkadaştan şimdiden özür diliyoruz. Burayı anlayabilen
    arkadaş lütfen yorumda açıklasın :)) çünkü bizde anlamadık.
    */
    const exam_didnt_start_now =
      prevState.exam.status !== 1 && this.state.exam.status === 1;
    const exam_opened_now =
      prevState.exam.status !== 2 && this.state.exam.status === 2;
    const exam_closed_now =
      prevState.exam.status !== 3 && this.state.exam.status === 3;
    const hide_questions_after_finish =
      this.state.exam.hide_questions_after_finish;
    const prev_hide_questions_after_finish =
      prevState.exam.hide_questions_after_finish;
    const filter = {
      exam_id: this.state.exam.id,
      exam_questions_id: this.props.examQuestionsId,
    };

    if (exam_opened_now) {
      this.fetchQuestions(filter);
    }
    if (exam_closed_now) {
      if (hide_questions_after_finish) {
        this.hideQuestions();
      } else this.fetchQuestions(filter);
    } else if (
      !prev_hide_questions_after_finish &&
      hide_questions_after_finish &&
      this.state.exam.status === 3
    ) {
      this.hideQuestions();
    } else if (
      prev_hide_questions_after_finish &&
      !hide_questions_after_finish &&
      this.state.exam.status === 3
    ) {
      this.fetchQuestions(filter);
    }
    if (exam_didnt_start_now) this.setState({ questions: [] });

    // if (
    //   ![2, 3].includes(prevState.exam.status) &&
    //   [2, 3].includes(this.state.exam.status)
    // ) {
    //   const filter = {
    //     exam_id: this.state.exam.id,
    //     exam_questions_id: this.props.examQuestionsId,
    //   };

    //   this.fetchQuestions(filter);
    // } else if (prevState.exam.status !== 1 && this.state.exam.status === 1)
    //   this.setState({ questions: [] });

    if (prevProps.user?.uid !== this.props.user?.uid) {
      this.listenTeamAnswer();
    }
  }
  hideQuestions = () => {
    this.setState({ questions: [] });
  };

  fetchQuestions = async (filter) => {
    const { exam } = this.state;
    const questions = await getQuestions(filter);
    this.setState({ questions });
    if (exam.status === 3 && exam.hide_questions_after_finish === true) {
      this.setState({ questions: [] });
    }
  };

  listenExam = () => {
    const { match, linkedExamId } = this.props;
    let examId = linkedExamId ? linkedExamId : match.params.id;

    listenExamById(this.examPageRef.current, { examId }, (exam) => {
      this.setState({ exam });
    });
  };

  listenTeamAnswer = () => {
    const { match, user, linkedExamId, linkedUserId } = this.props;
    const examId = linkedExamId ? linkedExamId : match.params.id;
    const userId = linkedUserId ? linkedUserId : user.uid;

    listenAnswerForm({ examId, userId }, this.setForm);
  };

  setForm = (formDB) => {
    const { user, setNotification } = this.props;
    const { exam } = this.state;
    const prevForm = this.state.form;
    const newForm = formDB;

    if (prevForm) {
      Object.keys(newForm).forEach((q_uid) => {
        const newQ = newForm[q_uid];
        const previousQ = prevForm[q_uid];
        const questionObject = this.state.questions.find((q) => q.id === q_uid);

        // ==============>>   burası takım halinde sınava girildiğinde tekrar düzenlenecek

        if (!questionObject) return; // bu bir soru değil de form üzerindeki başka bir data olabilir. Bu durumda kontrol yapmadan skip et.

        let title =
          questionObject && questionObject.title
            ? questionObject.title
            : "bir sorunun";

        if (
          newQ !== undefined &&
          previousQ !== undefined &&
          newQ.response !== undefined &&
          previousQ.response !== undefined &&
          newQ.response !== previousQ.response
        ) {
          const messageEnd =
            newQ.response === ""
              ? "sildi"
              : newQ.response + " olarak değiştirdi.";
          const messageTextForChangedQuestion =
            newQ.responder_name + " " + title + " cevabını " + messageEnd;
          exam &&
            exam.show_responders_name &&
            setNotification({
              variant: "success",
              message: messageTextForChangedQuestion,
            });
        }
      });
    }
    this.setState({
      form: newForm,
    });
  };

  renderExplanationBeforeStart = () => {
    const exp = this.state.exam.explanation_before_start;
    if (exp) {
      return (
        <p
          style={{
            textAlign: "start",
            border: "solid chocolate",
            whiteSpace: "pre-wrap",
            borderRadius: 15,
            padding: 15,
            maxWidth: 700,
          }}
        >
          {exp}
        </p>
      );
    }
  };

  renderExplanationAfterEnd = () => {
    const exp = this.state.exam.explanation_after_end;
    if (exp) {
      return (
        <p
          style={{
            textAlign: "start",
            border: "solid chocolate",
            whiteSpace: "pre-wrap",
            borderRadius: 15,
            padding: 15,
            maxWidth: 700,
          }}
        >
          {exp}
        </p>
      );
    }
  };

  renderExplanationDuringExam = () => {
    const exp = this.state.exam.explanation_during_exam;
    if (exp) {
      return (
        <p
          style={{
            textAlign: "start",
            border: "solid chocolate",
            whiteSpace: "pre-wrap",
            borderRadius: 15,
            padding: 15,
            maxWidth: 700,
          }}
        >
          {exp}
        </p>
      );
    }
  };

  renderExamResult = () => {
    const result = this.state.form.result;
    if (!!result) {
      return (
        <p
          style={{
            textAlign: "start",
            border: "solid chocolate",
            whiteSpace: "pre-wrap",
            borderRadius: 15,
            padding: 15,
            maxWidth: 700,
          }}
        >
          {result}
        </p>
      );
    }
  };

  renderAnswerFormInfo = () => {
    const { user } = this.props; // uid geliyor
  };

  renderExamInfo = () => {
    const { user, linkedUserName } = this.props;
    const { exam, form } = this.state;

    let exam_or_duration_end_at_string = "";

    try {
      if (form?.start_at) {
        let exam_closed_at = new Date(exam.end_date);
        let duration_end_at = new Date(
          new Date(form.start_at).getTime() + exam.duration * 60 * 1000
        );

        if (duration_end_at > exam_closed_at)
          exam_or_duration_end_at_string = createStringFromDate(exam_closed_at);
        else
          exam_or_duration_end_at_string =
            createStringFromDate(duration_end_at);
        const [date, time] = exam_or_duration_end_at_string.split(" ");
        exam_or_duration_end_at_string = `${date} - ${time}`;
      }
    } catch (error) {
      console.log(error);
    }
    const userName = linkedUserName ? linkedUserName : user ? user.name : "";
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1
          style={{
            color: "#ff5e00",
            fontFamily: "monospace",
            fontSize: "2.5rem",
          }}
        >
          {exam.header}
        </h1>
        <h2 style={{ fontSize: "1.3rem" }}>{exam.description}</h2>
        <p style={{ fontSize: "1.2rem" }}>{`Merhaba ${userName}`}</p>

        <div className="dateTable">
          <TableContainer component={Paper}>
            <Table size="medium">
              <TableHead>
                {/* {form.start_at_string && <TableRow >
                  <TableCell align="left">Sınav Başlama Tarihiniz</TableCell>
                  <TableCell align="right">{
                    <span>
                      {form.start_at_string}
                    </span>}</TableCell>
                </TableRow>} */}
                {form.last_response_updated_at_string &&
                  exam.show_last_response_time_on_screen === true && (
                    <TableRow>
                      <TableCell align="left">
                        Son Cevap Güncelleme Tarihiniz
                      </TableCell>
                      <TableCell align="right">
                        <span>{form.last_response_updated_at_string}</span>
                      </TableCell>
                    </TableRow>
                  )}
                {exam.duration &&
                  !form.finished_at &&
                  exam.status === 2 &&
                  form.start_at && (
                    <TableRow>
                      <TableCell align="left">
                        Cevap Girebileceğiniz Son Tarih
                      </TableCell>
                      <TableCell align="right">
                        <span
                          style={{
                            color: "#ff5e00",
                            fontFamily: "monospace",
                            fontSize: "1rem",
                            fontWeight: "bold",
                          }}
                        >
                          {exam_or_duration_end_at_string}
                        </span>
                      </TableCell>
                    </TableRow>
                  )}

                {exam.duration && form.finished_at_string && (
                  <TableRow>
                    <TableCell align="center" colSpan={3}>
                      Sınav Bitirme Tarihiniz
                    </TableCell>
                    <TableCell align="right">
                      {<span>{form.finished_at_string}</span>}
                    </TableCell>
                  </TableRow>
                )}
              </TableHead>
            </Table>
          </TableContainer>
        </div>

        {exam.status === 1 && this.renderExplanationBeforeStart()}
        {exam.status === 2 &&
          form.start_at &&
          !form.finished_at &&
          this.renderExplanationDuringExam()}
        {((exam.status === 2 && form.finished_at) || exam.status === 3) &&
          this.renderExplanationAfterEnd()}
        {form.result && this.renderExamResult()}
      </div>
    );
  };

  startExam = () => {
    const { user, linkedUserId, match, linkedExamId } = this.props;
    let userId = linkedUserId ? linkedUserId : user.uid;
    const { exam } = this.state;
    let examId = linkedExamId ? linkedExamId : match.params.id;
    this.unsubTimeout = setTimeout(() => {
      captureScreenshot(this.examPageRef.current, examId, userId);
    }, exam.duration * 60 * 1000);
    createAnswerFormAndStartExam({
      examId: exam.id,
      userId: linkedUserId ?? user.uid,
    });
  };

  onFinishButtonClick = async () => {
    const { form } = this.state;
    const { match, linkedExamId, user, linkedUserId } = this.props;
    let userId = linkedUserId ? linkedUserId : user.uid;
    let examId = linkedExamId ? linkedExamId : match.params.id;
    if (
      window.confirm(
        "Sınavı bitirmek istediğinize emin misiniz? (Onaylarsanız süreniz kalsa bile artık cevap giremeyeceksiniz.)"
      )
    ) {
      if (window.confirm("Emin misiniz?")) {
        await captureScreenshot(this.examPageRef.current, examId, userId);
        clearTimeout(this.unsubTimeout);
        //TODO: Bu metinleri düzelt.
        const res = await finishExam(form.id);
      }
    }
  };

  disableAllQuestions = async () => {
    const { exam, form } = this.state;
    this.setState({ exam: { ...this.state.exam, status: 3 } });
    if (exam.hide_questions_after_finish) {
      await finishExam(form.id);
    }
  };
  checkIfExamDurationIsOver = () => {
    const { exam, form } = this.state;

    if (
      exam.duration &&
      new Date().getTime() >
        form.start_at.getTime() + exam.duration * 60 * 1000 + 10000 // 10 saniye ekledik
    ) {
      return true;
    } else return false;
  };

  render() {
    const { user, linkedUserId, linkedUserName } = this.props;
    const { questions, exam, form } = this.state;
    const isStartButtonVisible = !form.start_at && exam.status === 2;

    const shouldDisplayQuestions =
      (form.start_at &&
        exam.status === 2 &&
        !(exam.hide_questions_after_finish && form.finished_at)) ||
      (exam.status === 3 && !exam.hide_questions_after_finish);
    const show_respoders_name =
      exam && exam.show_responders_name ? exam.show_responders_name : false;

    const isFinishButtonVisible =
      exam &&
      exam.display_finish_exam_button &&
      form.start_at &&
      !form.finished_at &&
      exam.status === 2 &&
      !this.checkIfExamDurationIsOver();
    const content = (
      <div className="questionsInExamPage" ref={this.examPageRef}>
        {this.renderExamInfo()}
        {isStartButtonVisible && (
          <MaterialButton
            variant="contained"
            color="secondary"
            onClick={this.startExam}
            size="large"
          >
            SINAVA BAŞLA
          </MaterialButton>
        )}

        {shouldDisplayQuestions &&
          questions.map((question, question_index) => (
            <QuestionCard
              key={question.id}
              inExamPage
              question={question}
              question_index={question_index}
              examId={exam.id}
              examStatus={exam.status}
              disableAllQuestions={() => {
                this.setState({ exam: { ...this.state.exam, status: 3 } });
                finishExam();
              }}
              responseOnForm={this.state.form[question.id]}
              linkedUserId={linkedUserId}
              linkedUserName={linkedUserName}
              showRespodersName={show_respoders_name}
            />
          ))}

        {isFinishButtonVisible && (
          <MaterialButton
            variant="contained"
            color="success"
            size="large"
            style={{ fontSize: 22 }}
            onClick={this.onFinishButtonClick}
          >
            SINAVI BİTİR
          </MaterialButton>
        )}
      </div>
    );

    return (
      <div style={{ backgroundColor: "#dbdad6" }}>
        {user && user.name ? (
          <HomeTemplate content={content} />
        ) : (
          <ExternalExamTemplate content={content} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  additionalInfo: state.auth.additionalInfo,
  // exam: state.exams.examList.data.find((e) => e.id === props.match.params.id),
  questionList: state.exams.questionList,
});

const mapDispatchToProps = (dispatch) => {
  return {
    examQuestions: (filter) => dispatch(getQuestionList(filter)),
    setNotification: (notification) => dispatch(setNotification(notification)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExamPage);
