// import { db } from "./firebaseConfig";
// import "firebase/firestore";
import axios from "axios";
// import { Users } from "../../functions/db";
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";
import { getApp } from "firebase/app";

const api = axios.create({
  // baseURL: "https://us-central1-exam-3125b.cloudfunctions.net/api",
  baseURL: "http://localhost:5001/exam-3125b/us-central1/api", // firebase emulators:start   komutu ile cloud fonksiyonları deploy öncesi localde test edebilirsin.
  //   timeout: 15000,
  headers: { "Content-Type": "application/json", Accept: "application/json" },
});

api.interceptors.request.use((config) => {
  console.log(config);
  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    return error.response;
    // throw new axios.Cancel("Error happened!");
  }
);

const functions = getFunctions(getApp());
connectFunctionsEmulator(functions, "localhost", 5001);

export const purchaseExamOnCloudFunctions = async (exam_id) => {
  // const functions = getFunctions();
  const purchaseExam = httpsCallable(functions, "purchaseExam");
  const res = await purchaseExam({ exam_id });
  return res.data;
};

export default api;

// const pullSmthFromFunctions = () => {
//   const cloudFunctionArgumans = "/readData";

//   return axiosInstance
//     .get(cloudFunctionArgumans)
//     .then((response) => {
//       console.log("Fonksiyonlar cevap döndü: ", response);
//       return response.data;
//     })
//     .catch((error) => {
//       console.log("readData  error", error);
//       return {
//         message: "HATA:",
//         exam: null,
//         formId: null,
//         questions: [],
//       };
//     });
// };

// export default axiosInstance;
