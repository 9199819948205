import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "@mui/material";
import { getCreatedExamList } from "../../store/slices/examSlice";
import { ExternalExamTemplate } from "../../components/templates/externalExamTemplate/externalExamTemplate";
import { HomeTemplate } from "../../components/templates/homeTemplate/homeTemplate";
import ExamList from "./examList";
import { createNewExam } from "../../db/examMethods";

/**
 Burası Kullanıcının oluşturduğu sunavları listelemek için kullandığımız sayfa
 */

export class UserCreatedExamsPage extends Component {
  constructor(props) {
    super(props);
    this.exam_questions_id = "";
  }

  onAddNewExamButtonClick = async () => {
    const { user, fetchUserCreatedExams } = this.props;
    console.log('user', user)
    await createNewExam();
    const creator_id = user.uid;
    await fetchUserCreatedExams(creator_id);
  };

   renderAddNewExamButton = () => (
    <Button
      variant="contained"
      color="primary"
      onClick={this.onAddNewExamButtonClick}
    >
      Yeni Sınav Ekle
    </Button>
  );

   render() {
    return (
      <HomeTemplate
        content={
          <div>
            <div>SINAVLARINIZ</div>
            <ExamList created />
            {this.renderAddNewExamButton()}
          </div>
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserCreatedExams: (creator_id) =>
      dispatch(getCreatedExamList(creator_id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserCreatedExamsPage);
