import "./App.css";
import DynamicRouteGenerator from "./dynamicRouteGenerator";
import { withRouter } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import { setUser } from "./store/slices/authSlice";
import { listenUserAuthState } from "./db/authMethods";
import { FeedbackSnack } from "./components/organisms/feedbackTab/FeedbackSnack";
import { getExamList } from "./store/slices/examSlice";

class App extends Component {
  componentDidMount() {
    const { userSetter } = this.props;
    listenUserAuthState(userSetter);
  }
  render() {
    return (
      <div className="App">
        <CssBaseline />
        <DynamicRouteGenerator />
        <FeedbackSnack />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {};
const mapDispatchToProps = (dispatch, props) => ({
  userSetter: (u) => {
    dispatch(setUser(u));
    if (u && u.uid) dispatch(getExamList(u.uid)); //TODO: Bunun burada olması gereksiz data çekmeye sebep olabilir. Sadece ihtiyaç duyduğumuzda satın aldığımız sınavları çekmek daha mantıklı olabilir. Emin değilim.
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
