import React from "react";
import { connect } from "react-redux";
import { HomeTemplate } from "../../components/templates/homeTemplate/homeTemplate";
import "./examStorePage.css";

export const ExamStorePage = (props) => {
  return <HomeTemplate content={<div>ExamStorePage</div>} />;
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ExamStorePage);
