import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { db, firebaseAuth } from "../../db/firebaseConfig";
import {
  authUser,
  createProfile,
  fb_logout,
  fetchUserDetails,
} from "../../db/authMethods";

export const setUser = createAsyncThunk("auth/setUser", async (params) => {
  try {
    // const { email, uid, accessToken, phoneNumber } = params;
    const { uid } = params || {};
    if (uid) {
      const userData = await fetchUserDetails(uid);
      // const userData = user.data();
      return { ...params, ...userData };
    } else return {};
  } catch (error) {
    console.log(error);
  }
});

export const createUser = createAsyncThunk(
  "auth/createUser",
  async (params, { rejectWithValue }) => {
    try {
      const payload = await createProfile(params);
      console.log("payload in", payload);
      if (payload.error) return rejectWithValue(payload.error);
      else return payload;
    } catch (error) {
      console.log("Bilinmeyen hata: ", error);
      return rejectWithValue("Bilinmeyen hata.");
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  fb_logout();
});

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: {},
    additionalInfo: null,
  },
  // asenkron bir işleme bağlı olmayan global state objelerini burada set edebilirsin.
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    setUserInfo(state, action) {
      state.additionalInfo = action.payload;
    },
  },

  // asenkron fonksiyonların sonucuna göre set edilmesi gereken global state değişkenlerini burada güncelle.
  extraReducers: {
    [setUser.fulfilled]: (state, action) => {
      // state.user = action.payload;
      state.user = {
        ...action.payload,
        status: "fulfilled",
        // data: action.payload,
        error: null,
      };
    },
    [setUser.pending]: (state, action) => {
      state.user = {
        status: "pending",
        error: null,
      };
    },
    [setUser.rejected]: (state, action) => {
      state.user = {
        status: "rejected",
        error: action.payload,
      };
    },

    [createUser.fulfilled]: (state, action) => {
      state.user = action.payload;
      state.user = {
        ...action.payload,
        status: "fulfilled",
        // data: action.payload,
        error: null,
      };
    },
    [createUser.pending]: (state, action) => {
      state.user = {
        status: "pending",
        error: null,
      };
    },
    [createUser.rejected]: (state, action) => {
      state.user = {
        status: "rejected",
        error: action.payload,
      };
    },

    [logout.fulfilled]: (state, action) => {
      //state.user = action.payload;
      state.user = {
        status: "-",
        data: null,
        error: null,
      };
    },
    [logout.pending]: (state, action) => {
      state.user = {
        status: "pending",
        data: null,
        error: null,
      };
    },
    [logout.rejected]: (state, action) => {
      state.user = {
        status: "-",
        data: null,
        error: action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
// export const { signInSuccess, signInRequest } = authSlice.actions;
export const { setUserInfo } = authSlice.actions;
export default authSlice.reducer;
