// //User Part
// export const getUserInfo = "/user/me";

// //Exam Part
// export const getAllExams = "/exam/all";
// export const addExam = "/exam";
// export const getOneExam = (id) => `/exam/${id}`;
// export const deleteExam = (id) => `/exam/${id}`;
// export const updateExam = (id) => `/exam/${id}`;

// //Question Part

// export const getAllQuestions = "/question/all";
// export const AddQuestion = "/question";
// export const getOneQuestion = (id) => `/question/${id}`;
// export const deleteQuestion = (id) => `/question/${id}`;
// export const updateQuestion = (id) => `/question/${id}`;

// save answer

export const saveAnswers = "/exam/saveAnswers";
export const getExamQuestions = "/exam/get-exam";
