import React, { Component } from "react";
import { connect } from "react-redux";
import { getExamList, getCreatedExamList } from "../../store/slices/examSlice";
import Loading from "../../components/molecules/materialBackDrop/loading";
import MaterialBackDrop from "../../components/molecules/materialBackDrop/materialBackDrop";
import { setNotification } from "../../store/slices/examSlice";
import ExamTable from "../../components/organisms/exams/examsTable";

/**
 Burası kullanıcının satın aldığı veya oluşturduğu sınavların listelendiği sayfa
 */
class ExamList extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    const { user, fetchUserExams, fetchUserCreatedExams, created } = this.props;
    if (created) {
      const creator_id = user.uid;
      fetchUserCreatedExams(creator_id);
    } else {
      const examinee_id = user.uid;
      fetchUserExams(examinee_id);
    }
  }

  render() {
    const {
      examList,
      createdExamList,
      created,
      kanguru,
      kanguruExam,
      name,
      tc,
    } = this.props;
    const kanguruExams = { data: kanguruExam };
    // let exams = created ? createdExamList : examList;
    let exams = created ? createdExamList : kanguru ? kanguruExams : examList;

    return (
      <div>
        {exams.data === undefined || exams.data?.length === 0 ? (
          <span>No exams</span>
        ) : (
          <ExamTable
            exams={exams}
            created={created}
            kanguru={kanguru}
            name={name}
            tc={tc}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    examList: state.exams.examList,
    createdExamList: state.exams.createdExamList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserExams: (examinee_id) => dispatch(getExamList(examinee_id)),
    fetchUserCreatedExams: (creator_id) =>
      dispatch(getCreatedExamList(creator_id)),
    setNotification: (notification) => dispatch(setNotification(notification)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExamList);
