import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

const QuizFinished = (props) => {
  const { score, trueAnswers } = props;

  const renderQuizExplanation = () => {
    const exp = `Aldığınız puan ${score}
doğru yanıtladığınız soru sayısı ${trueAnswers}`;

    if (exp) {
      return (
        <p
          style={{
            textAlign: "start",
            border: "solid chocolate",
            whiteSpace: "pre-wrap",
            borderRadius: 15,
            padding: 15,
            maxWidth: 700,
          }}
        >
          {exp}
        </p>
      );
    }
  };

  const generatePlayAgainButton = () => {
    const { quizId, onRestart } = props;
    return (
      <div style={{ marginBottom: 10, marginTop: 10 }}>
        <Button color="primary" variant="contained" onClick={onRestart}>
          {"Tekrar Oyna"}
        </Button>{" "}
        <Link style={{ textDecoration: "none" }} to={"/"}>
          <Button color="primary" variant="contained">
            {"Ana Sayfa"}
          </Button>
        </Link>
      </div>
    );
  };

  return (
    <div>
      {renderQuizExplanation()}
      {generatePlayAgainButton()}
    </div>
  );
};

export default QuizFinished;
