import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { isUserAllowedToDoThis, pageList } from "./common/authorization";
import { connect } from "react-redux";

// Oturum açan kullanıcı var mı, varsa talep edilen sayfa için yetkisi var mı vs. kontrol eder ve uygun bir rota oluşturur.

class DynamicRouteGenerator extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { user } = this.props;

    return (
      <Switch>
        {Object.keys(pageList).map((pageUid, i) => {
          const pageProperties = pageList[pageUid];
          const isUserAllowedToVisit = isUserAllowedToDoThis(
            user,
            pageUid,
            "visit"
          );
          // console.log(
          //   "isUserAllowedToVisit " +
          //     isUserAllowedToVisit +
          //     " pageUid " +
          //     pageUid
          // );

          return (
            <Route
              key={pageUid}
              exact
              path={pageProperties.path}
              component={
                isUserAllowedToVisit
                  ? pageProperties.component
                  : pageList.login.component
              }
            />
          );
        })}

        <Redirect to="/" />
      </Switch>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DynamicRouteGenerator);
