import React, { Component } from "react";
import { connect } from "react-redux";
import { getQuizList } from "../../store/slices/examSlice";
import Loading from "../../components/molecules/materialBackDrop/loading";
import MaterialBackDrop from "../../components/molecules/materialBackDrop/materialBackDrop";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { setNotification } from "../../store/slices/examSlice";

export class QuizList extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { fetchQuizes } = this.props;
    const type = 1;
    fetchQuizes(type);
  }

  generateQuizListItem = (quiz) => {
    return (
      <div
        key={"quizListItem" + quiz.description}
        style={{ marginBottom: 10, marginTop: 10 }}
      >
        <Link style={{ textDecoration: "none" }} to={`/quizes/${quiz.id}`}>
          <Button color="primary" variant="contained">
            {quiz.description}
          </Button>
        </Link>
      </div>
    );
  };

  render() {
    const { quizList } = this.props;

    return (
      <div>
        {quizList.length === 0 ? (
          <span>No Quizes</span>
        ) : (
          quizList.map((quiz) => this.generateQuizListItem(quiz))
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    quizList: state.exams.quizList.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchQuizes: (type) => dispatch(getQuizList(type)),
    setNotification: (notification) => dispatch(setNotification(notification)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuizList);
