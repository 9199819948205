import React, { useEffect } from "react";
import { connect } from "react-redux";
// import Link from "@mui/material/Link";
import { Link } from "react-router-dom";
import { ExternalExamTemplate } from "../../components/templates/externalExamTemplate/externalExamTemplate";
import { HomeTemplate } from "../../components/templates/homeTemplate/homeTemplate";
import { Button } from "@mui/material";
import "./homePageStyle.css";
import { getPublicData } from "../../store/slices/examSlice";
import ExamCard from "../../components/organisms/examCard/examCard";
const content = <div>ANA SAYFA</div>;

/**
 Burası bizim ana sayfamız. Şuanda sadece admin girişinde seçeneklerle ilerleniyor ama giriş öncesi için ve admin olmayanlar için
 de ayrı ayrı işlemler yapılacak.
 */

const HomePage = (props) => {
  const { user, publicData } = props;
  const homePageExams = publicData.home_page_exams ?? [];

  useEffect(() => {
    // props.getInitialPublicData();
  }, []);


  const generateAnonymUserContent = () => {
    return (
      <div className="unsignedScreen">
        <div>ONLINE SINAV SİSTEMİ</div>
        {/* <img className="mainImage" src="/assets/images/main.png" alt="Logo" /> */}
        {/* <div className="homePageExamSuggestionsBox">
          {homePageExams.map((e) => {
            return <ExamCard key={e.id} exam={e} />;
          })}
        </div> */}
      </div>
    );
  };

  return (
    <div>
       <HomeTemplate content={generateAnonymUserContent()} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  publicData: state.exams.publicData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getInitialPublicData: (_) => dispatch(getPublicData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
