// user rolleri, sayfa url'leri ve hangi user hangi sayfaya erişir, hangi action'ları gerçekleştirebilir... Bu dosyada yer almakta.

import HomePage from "../pages/homePage/homePage";
import LoginPage from "../pages/loginPage/loginPage";
import SignupPage from "../pages/signupPage/signupPage";
import UserExamsPage from "../pages/userExamsPage/userExamsPage";
import UserCreatedExamsPage from "../pages/userExamsPage/userCreatedExamsPage";
import ExamPage from "../pages/userExamPage/examPage";
import LinkedExamPage from "../pages/userExamPage/linkedExamPage";
import CreateAndEditExamPage from "../pages/userExamPage/createAndEditExamPage";
import QuestionBankPage from "../pages/questionPage/questionBankPage";
// import AddQuestionToExamPage from "../pages/userExamPage/addQuestionToExamPage";
import CouponEntryPage from "../pages/couponEntryPage/couponEntryPage";
import ExamResultPage from "../pages/examResultPage/examResultPage";
import FreeQuizesPage from "../pages/freeQuizesPage/freeQuizesPage";
import { QuizPage } from "../pages/quizPage/quizPage";
import ExamIntroPage from "../pages/examIntroPage/examIntroPage";
import ExamStorePage from "../pages/examStorePage/examStorePage";
import { Redirect } from "react-router-dom";
import React from "react";

// permissionRules: bu sayfada yapılabilen işlemler için rollere göre kurallar belirlemeye yarıyor.
// herhangi bir işlem için kural belirlenmediyse, o sayfaya erişen herkes o işlemi yapabilir. (firebase rules'ta tam tersi, o daha strict)
// kural varsa, sadece kurala  uyan roller o işlemi yapabilecek.
// visit kuralı, o sayfaya erişme yetkisini belirler. örneğin loginde visit kuralı yok, isteyen herkes login sayfasına ulaşabilir.


const RedirectToKanguru = () => {
  return <Redirect to ={"/vendor/kanguru"} />;
};

const pageList = {
  home: {
    path: "/",
    component: HomePage,
    permissionRules: {},
  },
  signup: {
    path: "/signup",
    component: SignupPage,
    permissionRules: {},
  },
  login: {
    path: "/login",
    component: LoginPage,
    permissionRules: {},
  },
  userExams: {
    path: "/userExams",
    component: UserExamsPage,
    permissionRules: {
      // visit: { admin: true, examManager: true, examinee: true },
    },
  },
  userCreatedExams: {
    path: "/userCreatedExams",
    component: UserCreatedExamsPage,
    permissionRules: {
      visit: { admin: true, examManager: true },
    },
  },
  userQuestions: {
    path: "/userQuestions",
    component: QuestionBankPage,
    permissionRules: {
      visit: { examManager: true },
    },
  },
  examDetail: {
    path: "/userExams/:id",
    component: ExamPage,
    permissionRules: {
      // visit: { admin: true, examManager: true, examinee: true },
    },
  },
  createdExamDetail: {
    path: "/userCreatedExams/:id",
    component: CreateAndEditExamPage,
    permissionRules: {
      // visit: { admin: true, examManager: true, examinee: true },
    },
  },
  linkedExamDetail: {
    path: "/linkedExam/:values",
    component: LinkedExamPage,
    permissionRules: {},
  },
  quizes: {
    path: "/quizes",
    component: FreeQuizesPage,
    permissionRules: {},
  },
  quiz: {
    path: "/quizes/:id",
    component: QuizPage,
    permissionRules: {},
  },
  // addQuestionToExam: {
  //   path: "/questionBank/:id",
  //   component: AddQuestionToExamPage,
  //   permissionRules: { admin: true, examManager: true, examinee: true },
  // },
  couponEntryPage: {
    path: "/vendor/:vendor_key",
    component: CouponEntryPage,
    permissionRules: {},
  },
  cekirgeEntryPage: {
    //TODO: bunu ve kanguruyu pagelist'ten silmek lazım. Bunlar öğrencilerine /kanguru yerine /vendor/kanguru adresini vermeliler... Eski öğrencilerin sistemi bozulmasın diye bunu koyuduk.
    path: "/cekirge",
    component: RedirectToKanguru,
    permissionRules: {},
  },
  kanguruEntryPage: {
    path: "/kanguru",
    component: RedirectToKanguru,
    permissionRules: {},
  },

  examResult: {
    path: "/result/:exam_id",
    component: ExamResultPage,
    permissionRules: {},
  },
  examIntroPage: {
    path: "/exam/:exam_id",
    component: ExamIntroPage,
    permissionRules: {},
  },
  examStorePage: {
    path: "/examStore",
    component: ExamStorePage,
    permissionRules: {},
  },
};

const isUserAllowedToDoThis = (user, pageUid, operation_name) => {
  // kural tanımlanmadıysa geniş yetki verilmesi için true döner.
  // kural tanımlandıysa, sadece kurala uyanlar için true döner.

  if (!user || !user.role) {
    user = { role: "anonym" };
  }

  const permissionRules =
    pageList[pageUid] && pageList[pageUid].permissionRules;

  if (user && user.role && permissionRules && permissionRules[operation_name]) {
    const rule = permissionRules[operation_name];
    return rule[user.role];
  } else return true;
};


export { pageList, isUserAllowedToDoThis };
