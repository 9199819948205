import React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const MaterialBackDrop = () => {
  return (
    <Backdrop style={{ zIndex: 1, color: "#fff" }} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default MaterialBackDrop;
