import React, { Component } from "react";
import { connect } from "react-redux";

import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createUser } from "../../store/slices/authSlice";
import { pullSmthFromFunctions } from "../../db/cloudFunctionsApiCalls";
import MaterialBackDrop from "../../components/molecules/materialBackDrop/materialBackDrop";
import { Redirect } from "react-router-dom";
import { pageList } from "../../common/authorization";
import "./signupPage.css";
import { HomeTemplate } from "../../components/templates/homeTemplate/homeTemplate";
import { setNotification } from "../../store/slices/examSlice";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
export class SignupPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      pass: "",
      name: "",
      isEmailVerificationDialogClosedOnce: false,
      isEmailVerificationDialogOpen: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.user?.status !== "rejected" &&
      this.props.user?.status === "rejected"
    ) {
      this.props.setNotification({
        variant: "error",
        message: "Kayıt başarısız: " + this.props.user?.error,
      });
    } else if (!prevProps.user?.uid && this.props.user?.uid) {
      console.log("this.props.user", this.props.user);
      this.props.setNotification({
        variant: "success",
        message: "Kaydınız başarıyla gerçekleştirildi.",
      });

      this.setState({ isEmailVerificationDialogOpen: true });
    }
  }

  signUp = async (e) => {
    e.preventDefault();
    alert("Bu sayfa aktif değildir!");
    return;
    
    const { email, pass, name } = this.state;

    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      this.props.setNotification({
        variant: "error",
        message: "Hatalı bir e-posta adresi girdiniz!",
      });
      return;
    } else if (pass.length < 6) {
      this.props.setNotification({
        variant: "error",
        message: "Şifre en az 6 karakter uzunluğunda olmalı",
      });
      return;
    } else if (name.length < 3) {
      this.props.setNotification({
        variant: "error",
        message: "Lütfen isminizi giriniz.",
      });
      return;
    }

    const result = await this.props.signUpWithEmailAndPass({
      email,
      pass,
      name,
    });

    console.log('result of signup----', result)
  };

  render() {
    const { user = {} } = this.props;
    const {
      isEmailVerificationDialogClosedOnce,
      isEmailVerificationDialogOpen,
    } = this.state;

    if (
      user.status === "fulfilled" &&
      user.uid &&
      isEmailVerificationDialogClosedOnce
    )
      return <Redirect to={pageList.home.path} />;

    const emailVerificationDialog = user.uid && (
      <Dialog
        open={isEmailVerificationDialogOpen}
        onClose={() =>
          this.setState({
            isEmailVerificationDialogOpen: false,
            isEmailVerificationDialogClosedOnce: true,
          })
        }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"E-Posta Doğrulama"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {"Lütfen " +
              user.email +
              " adresinize gönderilen doğrulama linkine tıklayarak hesabınızı aktifleştiriniz."}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              this.setState({
                isEmailVerificationDialogOpen: false,
                isEmailVerificationDialogClosedOnce: true,
              })
            }
          >
            Tamam
          </Button>
        </DialogActions>
      </Dialog>
    );

    return (
      <HomeTemplate
        content={
          <Container maxWidth="xs" component="main">
            {emailVerificationDialog}
            {user.status === "pending" && <MaterialBackDrop />}
            <form
              autoSave="on"
              onSubmit={this.signUp}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "10vh",
              }}
            >
              <Avatar style={{ marginBottom: 10 }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                KAYIT EKRANI
              </Typography>
              <TextField
               disabled
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Adı-Soyadı"
                name="name"
                autoComplete="name"
                autoFocus
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
              />
              <TextField
                disabled
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="E-Posta"
                name="email"
                autoComplete="email"
                //autoFocus
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
              />
              <TextField
                disabled
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Şifre"
                type="password"
                autoComplete="current-password"
                value={this.state.pass}
                onChange={(e) => this.setState({ pass: e.target.value })}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                style={{ marginTop: 20 }}
              >
                KAYDOL
              </Button>

              <div className="doYouHaveAccountBox">
                <span style={{ marginRight: 12 }}>
                  Dahan önce kayıt oldunuz mu?
                </span>
                <Link className="loginPageLink" href="/login" variant="body2">
                  {"OTURUM AÇIN"}
                </Link>
              </div>
            </form>

            <Box mt={8}>{/* <Copyright /> */}</Box>
          </Container>
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    signUpWithEmailAndPass: (userInfo) => dispatch(createUser(userInfo)),
    setNotification: (notification) => dispatch(setNotification(notification)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SignupPage);
