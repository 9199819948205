export const EXAM_STATUS_TYPES = {
  1: "Sınav Henüz Başlamadı",
  2: "Sınav Devam Ediyor",
  3: "Sınav Bitti",
};

export const QUESTION_CATEGORIES = { // TODO: Bu veritabanından gelmeli, dinamik olmalı, yeni itemler eklenebilmeli
  "ZEKA SORULARI": ["Soru İşareti", "Hangisi Farklı", "Topla Topla"],
  TARİH: ["Cumhuriyet Tarihi", "Osmanlı Tarihi", "Yakın Tarih"],
  BİLİM: ["Biyoloji", "Kimya", "Fizik", "Matematik"],
  "GENEL KÜLTÜR": [
    "Sinema",
    "Spor",
    "Kültür Sanat",
    "Beslenme",
    "Coğrafya",
    "Bilim",
    "Edebiyat",
    "Tarih",
    "Müzik",
    "Sağlık",
    "Tiyatro",
    "Moda",
    "Resim",
    "Felsefe",
  ],
};

export const QUESTION_DIFFICULTIES = {
  1: "1 Çok kolay",
  2: "2 Kolay",
  3: "3 Orta",
  4: "4 Zor",
  5: "5 Çok zor",
};
