import { Button, TextField, Typography, Modal, Box, Grid } from "@mui/material";
import React, { Component, createRef } from "react";
import Fab from "@mui/material/Fab";
import EditIcon from "@mui/icons-material/Edit";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import MaterialBackDrop from "../../components/molecules/materialBackDrop/materialBackDrop";
// import Link from "@mui/material/Link";
import { ExternalExamTemplate } from "../../components/templates/externalExamTemplate/externalExamTemplate";
import {
  fetchVendorsPublicData,
  getCouponData,
  changeVendorsPublicData,
} from "../../db/examMethods";

import ExamList from "../userExamsPage/examList";

/**
 Bu page bizim şu an sadece kanguru için kullandığımız bir sayfa. İlerde tabiki deişiklikleri olacak. Şu anda biz kanguru tarafından
 gelenlerin tcleri ile girdi yapıp onları sınavlarına yönlendirdiğimiz kısım.
 */

export class CouponEntryPage extends Component {
  vendorPublicLogoUrl = createRef();
  vendorPublicTitle = createRef();
  vendorPublicSubtitle = createRef();
  vendorPublicDescription = createRef();
  vendorPublicBottomDescription = createRef();

  componentDidMount() {
    this.checkVendorPublicDataOfThisScreen();
  }
  constructor(props) {
    super(props);
    this.state = {
      isVendorModalOpen: false,
      tc: "",
      isExamListVisible: false,
      loading: false,
      exams: [],
      name: "",
      vendorPublicData: {},
    };
  }

  checkVendorPublicDataOfThisScreen = async () => {
    let vendorKey = this.props.match.params.vendor_key;
    let vendorPublicData = await fetchVendorsPublicData(vendorKey);
    this.setState({ vendorPublicData });
    const { user } = this.props;
  };
  controlCodeAndRedirect = async () => {
    this.setState({ loading: true });
    const res = await getCouponData(this.state.tc);

    const { message, exams, name = this.state.tc } = res;
    if (message) {
      alert(message);
    } else if (exams.length > 0) {
      this.setState({
        isExamListVisible: true,
        loading: false,
        exams,
        name,
      });
    } else
      alert(
        "Sistemde verileriniz hatalı kaydedilmiş olabilir. Lütfen iletisim@kanguru-tr.com mail adresi üzerinden bilgi veriniz."
      );

    this.setState({ loading: false });
  };

  renderExamList = () => {
    const { exams, name, tc } = this.state;
    return <ExamList kanguru={true} kanguruExam={exams} name={name} tc={tc} />;
  };

  renderVendorDataEditModal = () => {
    const { isVendorModalOpen, vendorPublicData } = this.state;
    if (!isVendorModalOpen)
      return (
        <Fab
          color="secondary"
          style={{ position: "absolute", bottom: 16, right: 16 }}
          aria-label="edit"
          onClick={() => this.setState({ isVendorModalOpen: true })}
        >
          <EditIcon />
        </Fab>
      );
    // <Button
    //   variant="contained"
    //   color="primary"
    //   style={{ marginBottom: 20 }}
    //   onClick={() => this.setState({ isVendorModalOpen: true })}
    // >
    //   Sayfayı Düzenle
    // </Button>
    else {
      // const vendorDataCopy= {...vendorPublicData};
      const rowItemStyle = { width: 700, marginBottom: 16 };
      return (
        <Modal
          open={true}
          onClose={() => {
            this.setState({ isVendorModalOpen: false });
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              background: "white",
            }}
          >
            <TextField
              id="outlined-multiline-static"
              label="Logo Url"
              style={rowItemStyle}
              multiline
              inputRef={this.vendorPublicLogoUrl}
              defaultValue={vendorPublicData.logo_url}
            />

            <TextField
              id="outlined-multiline-static"
              label="Başlık"
              style={rowItemStyle}
              multiline
              inputRef={this.vendorPublicTitle}
              defaultValue={vendorPublicData.page_title}
            />

            <TextField
              id="outlined-multiline-static"
              label="Alt başlık"
              style={rowItemStyle}
              multiline
              inputRef={this.vendorPublicSubtitle}
              defaultValue={vendorPublicData.page_subtitle}
            />

            <TextField
              id="outlined-multiline-static"
              label="Açıklama metni"
              style={rowItemStyle}
              multiline
              inputRef={this.vendorPublicDescription}
              defaultValue={vendorPublicData.page_description}
            />
            <TextField
              id="outlined-multiline-static"
              label="Alt açıklama metni"
              style={rowItemStyle}
              multiline
              inputRef={this.vendorPublicBottomDescription}
              defaultValue={vendorPublicData.bottom_description}
            />
            <div style={{ marginTop: 20 }}>
              <Button
                sx={{ mx: 4 }}
                variant="contained"
                color="success"
                onClick={async () => {
                  const newData = {
                    logo_url: this.vendorPublicLogoUrl.current.value,
                    page_title: this.vendorPublicTitle.current.value,
                    page_subtitle: this.vendorPublicSubtitle.current.value,
                    page_description:
                      this.vendorPublicDescription.current.value,
                    bottom_description:
                      this.vendorPublicBottomDescription.current.value,
                  };
                  await changeVendorsPublicData(newData);
                  await this.checkVendorPublicDataOfThisScreen();
                  this.setState({ isVendorModalOpen: false });
                }}
              >
                Kaydet
              </Button>
              <Button
                sx={{ mx: 4 }}
                variant="outlined"
                color="error"
                onClick={() => {
                  this.setState({ isVendorModalOpen: false });
                }}
              >
                İptal
              </Button>
            </div>
          </div>
        </Modal>
      );
    }
  };

  render() {
    const { tc, isExamListVisible, loading, vendorPublicData } = this.state;
    const vendorKey = this.props.match.params.vendor_key;
    const content = (
      <div style={{ marginTop: 16 }}>
        {vendorPublicData.logo_url && (
          <div>
            <img
              src={vendorPublicData.logo_url}
              style={{ maxWidth: 500, width: "100%", marginBottom: 10 }}
            />
          </div>
        )}
        <Typography
          style={{
            fontFamily: "monospace",
            color: "#f16101",
            fontSize: "calc(1.2rem + 1.4vw)",
            fontWeight: 600,
            marginBottom: 30,
            lineHeight: "normal",
          }}
        >
          {vendorPublicData.page_title || "ONLINE SINAVLAR"}
        </Typography>

        {!isExamListVisible && (
          <Box
            sx={{
              margin: "auto",
              maxWidth: 700,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {loading && <MaterialBackDrop />}

            {this.props.user.uid &&
              this.props.user.uid ==
                this.state.vendorPublicData.vendor_user_id &&
              this.renderVendorDataEditModal()}

            {this.state.vendorPublicData.page_subtitle && (
              <Typography
                component="h1"
                variant="h6"
                style={{
                  fontFamily: "monospace",
                  color: "#black",
                  fontSize: "calc(1rem + 1.2vw)",
                  fontWeight: 600,
                  marginBottom: 30,
                  lineHeight: "normal",
                }}
              >
                {this.state.vendorPublicData.page_subtitle}
              </Typography>
            )}

            {this.state.vendorPublicData.page_description && (
              <Typography
                component="p"
                style={{
                  fontFamily: "monospace",
                  fontSize: "calc(0.8rem + 0.8vw)",
                  marginBottom: 15,
                  lineHeight: "normal",
                }}
              >
                {this.state.vendorPublicData.page_description}
              </Typography>
            )}

            <TextField
              variant="outlined"
              margin="normal"
              label={
                vendorKey === "ibb" ? "Sınav Giriş Kodunuz " : "TC NUMARANIZ"
              }
              name={vendorKey === "ibb" ? "id" : "tc"}
              autoFocus
              value={tc}
              autoComplete="false"
              onChange={(e) => {
                const newVal = e.target.value.trim();
                if (vendorKey !== "ibb" && newVal.length > 11) {
                  alert("TC Numaranız 11 Haneli Olmalıdır!");
                } else if (!isNaN(newVal)) {
                  this.setState({ tc: newVal });
                }
              }}
            />

            <Button
              disabled={tc.length < 11}
              variant="contained"
              color="primary"
              style={{ marginTop: 20 }}
              onClick={this.controlCodeAndRedirect}
            >
              SINAVLARI GETİR
            </Button>

            <Typography
              component="h1"
              variant="h5"
              style={{
                fontFamily: "monospace",
                color: "#f16101",
                fontSize: "calc(1rem + 0.4vw)",
                fontWeight: 600,
                marginTop: 20,
                lineHeight: "normal",
              }}
            >
              {this.state.vendorPublicData.bottom_description}
            </Typography>
          </Box>
        )}
        {isExamListVisible && this.renderExamList()}
      </div>
    );

    return <ExternalExamTemplate content={content} />;
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(CouponEntryPage);
