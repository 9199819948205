import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";

import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import MaterialBackDrop from "../../components/molecules/materialBackDrop/materialBackDrop";
import { Redirect, useHistory } from "react-router-dom";
import { pageList } from "../../common/authorization";
import { authUser, requestPasswordReset } from "../../db/authMethods";
import { setNotification } from "../../store/slices/examSlice";
import { HomeTemplate } from "../../components/templates/homeTemplate/homeTemplate";
import { ClickAwayListener } from "@mui/material";
import MailLockIcon from "@mui/icons-material/MailLock";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export const LoginPage = ({ user, setNotification }) => {
  const [email, setEmail] = useState("");
  const [emailForPassReset, setEmailForPassReset] = useState("");
  const [pass, setPass] = useState("");
  const history = useHistory();
  const [passResetDialogOpen, setPassResetDialogOpen] = useState(false);

  useEffect(() => {
    if (user && user.status === "fulfilled" && user.uid) {
      history.goBack();
    }
  }, [user]);

  const sendPassResetLink = async () => {
    const res = await requestPasswordReset(emailForPassReset);
    alert(res);
    setPassResetDialogOpen(false);
  };

  const passResetDialog = (
    <Dialog
      open={passResetDialogOpen}
      onClose={() => setPassResetDialogOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Şifre Sıfırlama"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Şifrenizi unuttuysanız size tek kullanımlık şifre sıfırlama linki
          gönderebiliriz. Gönder tuşuna bastıktan sonra 10 dakika içinde mail
          adresinizi kontrol ediniz. (Link gelmediyse Spam kalasörünü kontrol
          etmeyi unutmayınız.)
        </DialogContentText>

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="E-Posta"
          name="email"
          autoComplete="email"
          autoFocus
          value={emailForPassReset}
          onChange={(e) => setEmailForPassReset(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setPassResetDialogOpen(false)}>İptal</Button>
        <Button onClick={sendPassResetLink} autoFocus>
          Link Gönder
        </Button>
      </DialogActions>
    </Dialog>
  );


  const signIn = async (e) => {
    e.preventDefault();
    if (pass.length < 6){
      alert("Şifre en az 6 karakter uzunluğunda olmalıdır.");
      return;
    }

    const res = await authUser(email, pass);
    if (res === "OK")
      setNotification({ variant: "success", message: "Giriş Baraşılı" });
    else
      setNotification({ variant: "error", message: "Giriş Başarısız: " + res });
  };

  return (
    <HomeTemplate
      content={
        <Container maxWidth="xs" component="main">
          {user.status === "pending" && <MaterialBackDrop />}
          <form
            autoSave="on"
            onSubmit={signIn}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "10vh",
            }}
          >
            <Avatar style={{ marginBottom: 10 }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              GİRİŞ
            </Typography>

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="E-Posta"
              name="email"
              autoComplete="email"
              //autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Şifre"
              type="password"
              autoComplete="current-password"
              value={pass}
              onChange={(e) => setPass(e.target.value)}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              style={{ marginTop: 20 }}
            >
              Giriş
            </Button>

            <div
              // style={{
              //   display: "flex",
              //   justifyContent: "space-between",
              //   width: "100%",
              //   marginTop: 20,
              // }}
              className="doYouHaveAccountBox"
            >
              <Button
                onClick={() => setPassResetDialogOpen(true)}
                startIcon={<MailLockIcon />}
                disabled
              >
                Şifre Sıfırlama
              </Button>

              <Link href="/signup" variant="body2">
                <Button>KAYDOL</Button>
              </Link>
            </div>
          </form>
          {passResetDialog}
          <Box mt={8}>{/* <Copyright /> */}</Box>
        </Container>
      }
    />
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setNotification: (notification) => dispatch(setNotification(notification)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
