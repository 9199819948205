import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "@mui/material";
import { setNotification } from "../../store/slices/examSlice";
import QuestionChoice from "../../components/organisms/questions/questionChoice";

/**
 Bunu başta bir sayfa olarak düşündük ama aldığımız bir hat doğrultusunda bunu componenta çevirmeye karar verdik. Ancak dosya
 yerini değiştirdiğimizde daha fazla hata vermeye başladığı için bu şekilde bıraktık yerini. O yüzden ben (Alper) yerinin
 değiştirilmemesini tavsiye ederim. İşlevine gelecek olursam, Sistemde login olmuş kullanıcının oluşturduğu soruları çekip
 bir soru bankası görüntüsünde checkbox componentına soruları sırayla gönderip ekranda gözükmesini sağlayıp soruları sınava ekleme
 işlevini yerine getirmeye çalışmaktadır.
 */

export class AddQuestionToExam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionsFromBank: [],
      addedQuestions: {},
    };
    this.examQuestions = this.props.examQuestions;
  }
  componentDidMount() {
    this.getQuestions();
  }

  addAndRemoveQuestion = (question, question_index, is_added) => {
    const { addedQuestions } = this.state;
    const addedQuestionsCopy = { ...addedQuestions };
    const addedQuestion = { ...question };
    delete addedQuestion.id;
    const q_id = question.id;
    is_added
      ? (addedQuestionsCopy[q_id] = { ...addedQuestion })
      : delete addedQuestionsCopy[q_id];
    this.setState({ addedQuestions: addedQuestionsCopy });
  };

  handleSave = async () => {
    const { addedQuestions } = this.state;
    const { handleSaveQuestionBank } = this.props;
    handleSaveQuestionBank(addedQuestions);
  };

  handleCancel = async () => {
    const { Cancel } = this.props;
    Cancel();
  };

  getQuestions = async () => {
    const { questionsFromBank } = this.props;
    this.setState({ questionsFromBank });
  };

  renderSaveButton = () => {
    return (
      <>
        <Button variant="contained" color="primary" onClick={this.handleSave}>
          Seçilen Soruları Sınava Ekle
        </Button>{" "}
        <Button variant="contained" color="primary" onClick={this.handleCancel}>
          İptal
        </Button>
      </>
    );
  };

  renderQuestions = () => {
    const { questionsFromBank } = this.state;
    return (
      <div>
        {this.renderSaveButton()}
        {questionsFromBank.map((question, question_index) => (
          <QuestionChoice
            question={question}
            question_index={question_index}
            examQuestions={this.examQuestions}
            addAndRemoveQuestion={this.addAndRemoveQuestion}
          />
        ))}
      </div>
    );
  };

  render() {
    return <div>{this.renderQuestions()}</div>;
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: (notification) => dispatch(setNotification(notification)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddQuestionToExam);
