import React, { Component } from "react";
import {
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  Grid,
  Button,
  Autocomplete,
  Select,
  MenuItem,
} from "@mui/material";
import QuestionCard from "./questionCard";
import { fetchAQuestions, saveQuestion } from "../../../db/examMethods";
import DeleteIcon from "@mui/icons-material/Delete";
import HelpIcon from '@mui/icons-material/Help';
import {
  QUESTION_DIFFICULTIES,
  QUESTION_CATEGORIES,
} from "../../../common/constants";

export default class QuestionModal extends Component {
  constructor(props) {
    super(props);
    const { question, edit } = this.props;
    this.state = {
      question: edit ? question : {},
      url: "",
    };
  }

  componentDidMount() {
    this.getQuestionFromQuestionsCollection();
  }

  getQuestionFromQuestionsCollection = async () => {
    if (this.props.question?.id) {
      const q = await fetchAQuestions(this.props.question.id);
      if (q.answer) {
        this.setState({
          question: { ...this.state.question, answer: q.answer },
        });
      }
    }
  };

  handleClose = () => {
    this.props.onClose();
  };

  handleSave = async () => {
    const { exam_id } = this.props;
    // let questionCopy = { ...question };
    // if (url !== "") questionCopy.question_image_path = url;
    const unchangedOriginalQuestion = this.props.question || {};
    const changedQuestion = this.state.question;
    await saveQuestion(unchangedOriginalQuestion, changedQuestion, exam_id);
    this.handleClose();
  };

  renderSaveCancelButtons = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: 100,
        gap: 200,
      }}
    >
      <Button variant="contained" color="secondary" onClick={this.handleClose}>
        İPTAL
      </Button>
      <Button variant="contained" color="primary" onClick={this.handleSave}>
        KAYDET
      </Button>
    </div>
  );

  renderMultipleChoice = (choice) => {
    const { question } = this.state;
    const choices = question.choices;

    return (
      <TextField
        variant="outlined"
        //defaultValue={choices && choices.choice_text}
        value={choices && choices[choice] && choices[choice].choice_text}
        onChange={(e) => {
          const newVal = e.target.value;
          // const oldChoice =
          //   question.choices && question.choices[choice]
          //     ? question.choices[choice]
          //     : {};

          const oldChoice = question.choices?.[choice];
          this.setState(
            {
              question: {
                ...question,
                choices: {
                  ...question.choices,
                  [choice]: {
                    ...oldChoice,
                    choice_text: newVal,
                  },
                },
              },
            },
            () => {}
          );
        }}
        fullWidth={true}
        size={"small"}
      />
    );
  };

  handleQuestionImageSelect = (e) => {
    const { question } = this.state;
    let questionCopy = { ...question };
    const oldUrl = questionCopy.question_image_path;

    let imageFile = "";
    let url = "";
    if (e) {
      imageFile = e.target.files[0];
      url = URL.createObjectURL(imageFile);
    }
    questionCopy.question_image_path = url;// eğer var olan bir resmi siliyorsak, bu url "" olacak, ve resim storage'dan silinecek az sonra.
    questionCopy.imageFile = imageFile;
    this.setState({ question: questionCopy, url: oldUrl });
  };

  handleAnswerImageSelect = (e, choice) => {
    const { question } = this.state;
    let imageFile = "";
    let url = "";
    if (e) {
      imageFile = e.target.files[0];
      url = URL.createObjectURL(imageFile);
    } 

    const oldChoice = question.choices?.[choice];
    this.setState({
      question: {
        ...question,
        choices: {
          ...question.choices,
          [choice]: {
            ...oldChoice,
            choice_image: url,
            image_file: imageFile,
          },
        },
      },
    });
  };

  renderInputs = () => {
    const { question } = this.state;
    let questionCopy = { ...question };

    const questionName = (
      <TextField
        variant="outlined"
        defaultValue={question.name}
        onChange={(e) => {
          const newVal = e.target.value;
          questionCopy.name = newVal;
          this.setState({ question: questionCopy });
        }}
        fullWidth={true}
        size={"small"}
      />
    );

    const questionGeneralCategory = (
      <Autocomplete
        size="small"
        disablePortal
        id="combo-box-demo"
        options={Object.keys(QUESTION_CATEGORIES).map((key) => {
          return { label: key };
        })}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} />}
        value={question.category}
        onChange={(event, selectedValue) => {
          questionCopy.category = selectedValue?.label ?? "";
          this.setState({ question: questionCopy });
        }}
      />
    );

    const questionSubCategory = (
      <Autocomplete
        size="small"
        disablePortal
        id="combo-box-demo"
        options={(QUESTION_CATEGORIES[question.category] ?? []).map(
          (subCtgr) => {
            return { label: subCtgr };
          }
        )}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} />}
        value={question.sub_category}
        onChange={(event, selectedValue) => {
          questionCopy.sub_category = selectedValue?.label ?? "";
          this.setState({ question: questionCopy });
        }}
      />
    );
    const questionCategory = (
      <TextField
        variant="outlined"
        defaultValue={question.question_category}
        onChange={(e) => {
          const newVal = e.target.value;
          questionCopy.question_category = newVal;
          this.setState({ question: questionCopy });
        }}
        fullWidth={true}
        size={"small"}
      />
    );

    const difficultyChooser = (
      <Autocomplete
        size="small"
        disablePortal
        id="combo-box-demo"
        options={Object.keys(QUESTION_DIFFICULTIES).map((k) => {
          const label = QUESTION_DIFFICULTIES[k];
          const id = k;
          return { label, id };
        })}
        sx={{ width: 200 }}
        renderInput={(params) => <TextField {...params} />}
        value={QUESTION_DIFFICULTIES[question.difficulty]}
        onChange={(event, selectedValue) => {
          questionCopy.difficulty = selectedValue?.id
            ? parseInt(selectedValue.id)
            : 0;
          this.setState({ question: questionCopy });
        }}
      />
    );

    const questionHeaderText = (
      <TextField
        multiline
        variant="outlined"
        defaultValue={question.question_header_text}
        onChange={(e) => {
          const newVal = e.target.value;
          questionCopy.question_header_text = newVal;
          this.setState({ question: questionCopy });
        }}
        fullWidth={true}
        size={"small"}
      />
    );

    const questionImageSelector = (
      <div>
        <input
          type="file"
          onChange={(e) => this.handleQuestionImageSelect(e)}
        />
        {question.question_image_path && (
          <Button>
            <DeleteIcon
              style={{ color: "red", border: "none" }}
              onClick={() => {
                if (
                  window.confirm("Görseli silmek istediğinize emin misiniz?")
                ) {
                  this.handleQuestionImageSelect(undefined);
                }
              }}
            />
          </Button>
        )}
      </div>
    );

    const questionText = (
      <TextField
        multiline
        variant="outlined"
        defaultValue={question.question_text}
        onChange={(e) => {
          const newVal = e.target.value;
          questionCopy.question_text = newVal;
          this.setState({ question: questionCopy });
        }}
        fullWidth={true}
        size={"small"}
      />
    );

    const questionAnswer = [2, 3].includes(question.type) && (
      <TextField
        multiline
        variant="outlined"
        value={question.answer}
        onChange={(e) => {
          const newVal = e.target.value;
          questionCopy.answer = newVal;
          this.setState({ question: questionCopy });
        }}
        fullWidth={true}
        size={"small"}
      />
    );

    const testQuestionAnswer = question.type === 1 && question.choices && (
      <Select
        size="small"
        sx={{ width: 200 }}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={question.answer || ""}
        label="Cevap"
        onChange={(e) => {
          const newVal = e.target.value;
          questionCopy.answer = newVal;
          this.setState({ question: questionCopy });
        }}
      >
        {Object.keys(question.choices)
          .sort((c1, c2) => c1.localeCompare(c2))
          .map((c) => (
            <MenuItem key={"answer_choice_" + c} value={c}>
              {c}
            </MenuItem>
          ))}
      </Select>
    );

    const questionType = (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <RadioGroup
          style={{ gap: 20 }}
          row
          onChange={(event) => {
            const newVal = event.target.value;
            const q_type = parseInt(newVal);
            questionCopy.type = q_type;
            this.setState({ question: questionCopy });
          }}
          value={this.state.categoryOption}
        >
          <FormControlLabel
            value="1"
            control={<Radio checked={question.type === 1 && true} />}
            label="Test"
          />
          <FormControlLabel
            value="2"
            control={<Radio checked={question.type === 2 && true} />}
            label="Metin"
          />
          <FormControlLabel
            value="3"
            control={<Radio checked={question.type === 3 && true} />}
            label="Sayı"
          />
          <FormControlLabel
            title="Açıklama kutuları eklemek içi bu seçeneği kullanabilirsiniz. Kullanıcıdan herhangi bir cevap girişi beklenmez."
            value="0"
            control={<Radio checked={question.type === 0 && true} />}
            label="Cevap Girişi Yok"
          />
        </RadioGroup>
      </div>
    );

    const answerArray = ["A", "B", "C", "D", "E", "F", "G", "H", "J"];

    const questionMultipleChoices = question.type === 1 && (
      <div>
        <RadioGroup
          row
          onChange={(event) => {
            let new_choice_columns = event.target.value;
            // const q_type = parseInt(newVal);
            questionCopy.choice_columns = parseInt(new_choice_columns);
            this.setState({ question: questionCopy });
          }}
          value={question?.choice_columns?.toString()}
        >
          <FormControlLabel
            value="1"
            control={
              <Radio
                checked={
                  !question.choice_columns || question.choice_columns === 1
                }
              />
            }
            label="Tek Sütun"
          />
          <FormControlLabel
            value="2"
            control={<Radio checked={question.choice_columns === 2} />}
            label="İki Sütun"
          />
        </RadioGroup>

        {answerArray.map((choice) => {
          return (
            <div
              style={{
                marginBottom: 10,
                display: "flex",
                alignItems: "center",
              }}
            >
              <span>{choice}</span>

              <span style={{ marginLeft: 20, maxWidth: 300 }}>
                {this.renderMultipleChoice(choice)}
              </span>

              <input
                type="file"
                onChange={(e) => this.handleAnswerImageSelect(e, choice)}
              />
              {question.choices?.[choice]?.choice_image && (
                <Button>
                  <DeleteIcon
                    style={{ color: "red", border: "none" }}
                    onClick={() => {
                      if (
                        window.confirm(
                          "Görseli silmek istediğinize emin misiniz?"
                        )
                      ) {
                        // this.handleSetEmptyImage(choice)
                        this.handleAnswerImageSelect(undefined, choice);
                      }
                    }}
                  />
                </Button>
              )}
            </div>
          );
        })}
      </div>
    );

    const permittedCharactersChoicesInput = question.type === 2 && (
      <TextField
        placeholder="Cevap girişinde tüm karakterlere izin verilir."
        fullWidth
        variant="outlined"
        size="small"
        value={question.permitted_characters_for_response}
        onChange={(e) => {
          const newVal = e.target.value.toLocaleUpperCase("tr-TR");
          questionCopy.permitted_characters_for_response = newVal;
          this.setState({ question: questionCopy });
        }}
      />
    );

    const minPermittedCharactersLengthInput = question.type === 2 && (
      <TextField
        type="number"
        inputProps={{ min: 0 }}
        placeholder="Sınırsız"
        variant="outlined"
        size="small"
        value={question.min_permitted_char_length}
        onChange={(e) => {
          const newVal = e.target.value;
          questionCopy.min_permitted_char_length = parseInt(newVal);
          this.setState({ question: questionCopy });
        }}
      />
    );

    const maxPermittedCharactersLengthInput = question.type === 2 && (
      <TextField
        type="number"
        inputProps={{ min: 0 }}
        placeholder="Sınırsız"
        variant="outlined"
        size="small"
        value={question.max_permitted_char_length}
        onChange={(e) => {
          const newVal = e.target.value;
          if (newVal === isNaN(newVal)) {
            questionCopy.max_permitted_char_length = "";
          } else questionCopy.max_permitted_char_length = parseInt(newVal);
          this.setState({ question: questionCopy });
        }}
      />
    );

    const { question_index } = this.props;
    const questionPreview = (
      <div style={{ position: "relative" }}>
        <QuestionCard
          question={question}
          question_index={question_index}
          fromModal
        />
      </div>
    );

    return (
      <Grid container spacing={2}>
        <Grid item xs={4} lg={6}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableBody>
                <DataRow
                  label={"Soru Adı"}
                  inputs={questionName}
                  title="Kullanıcılar bu bilgiyi görmez. Soru bankasında arama yaparken kullanılır. Örn: Tozoş 2021 Final Sınavı Soru-1 gibi tanımlayıcı isimler yazabilirsiniz."
                />
                <DataRow
                  label={"Üst Kategori"}
                  inputs={questionGeneralCategory}
                  title="Kullanıcılar bu bilgiyi görmez. Soru bankasında arama yaparken kullanılır."
                />

                <DataRow
                  label={"Alt Kategori"}
                  inputs={questionSubCategory}
                  title="Kullanıcılar bu bilgiyi görmez. Soru bankasında arama yaparken kullanılır."
                />

                <DataRow
                  label={"Yayınlanacak Kategori"}
                  inputs={questionCategory}
                  title="Her sorunun başlığının yanında bu bilgi yazar. Kullanıcılar bu bilgiyi görür."
                />
                <DataRow
                  label={"Görsel Üstü Metin"}
                  inputs={questionHeaderText}
                />
                <DataRow
                  label={"Görsel"}
                  inputs={questionImageSelector}
                  title="Lütfen resimlerinizin genişliğini max 700 piksel olarak boyutlandırdıktan sonra yükleyiniz."
                />
                <DataRow label={"Görsel Altı Metin"} inputs={questionText} />
                <DataRow label={"Cevap Tipi"} inputs={questionType} />
                {question.type === 1 && (
                  <DataRow
                    label={"Cevap Şıkları"}
                    inputs={questionMultipleChoices}
                  />
                )}
                {question.type === 2 && (
                  <DataRow
                    label={"İzin Verilen Karakterler"}
                    inputs={permittedCharactersChoicesInput}
                    title="Bu kutucuğa herhangi bir karakter yazmanız halinde öğrenciler cevap olarak sadece bu karakterleri kullanabilir. Boş bırakırsanız tüm karakterler kullanılabilir."
                  />
                )}
                {question.type === 2 && (
                  <DataRow
                    label={"İzin Verilen Minimum Karakter Sayısı"}
                    inputs={minPermittedCharactersLengthInput}
                    title="Kullanıcılar burada yazan sayıdan daha az karakterde cevap girişi yapamaz."
                  />
                )}
                {question.type === 2 && (
                  <DataRow
                    label={"İzin Verilen Maksimum Karakter Sayısı"}
                    inputs={maxPermittedCharactersLengthInput}
                    title="Kullanıcılar burada yazan sayıdan daha fazla karakterde cevap girişi yapamaz."
                  />
                )}
                {question.type !== 0 && (
                  <DataRow
                    label={"Doğru Cevap"}
                    inputs={
                      question.type === 1 ? testQuestionAnswer : questionAnswer
                    }
                    title="Sorunun cevabını buraya kaydetseniz bile ilk etapta kullanıcılar doğru cevapları göremez. Sınav Sonuçları sayfasından 'Cevapları Yayınla' butonuna bastığınızda cevaplar yayınlanır. Sorunun birden fazla cevabı varsa doğru cevaplar arasına & işareti koyarak boşluk bırakmadan cevapları girmeniz gerekmektedir. Örneğin: Emek&Çaba vb."
                  />
                )}
                <DataRow
                  label={"Zorluk Seviyesi"}
                  inputs={difficultyChooser}
                  title="Tahmini zorluk seviyesini girebilir ya da boş bırakabilirsiniz."
                />
              </TableBody>
            </Table>
          </TableContainer>
          {this.renderSaveCancelButtons()}
        </Grid>

        <Grid
          item
          xs={8}
          lg={6}
          style={{
            backgroundColor: "#c1c1c1",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {questionPreview}
        </Grid>
      </Grid>
    );
  };

  render() {
    return (
      <Dialog
        disableBackdropClick={true}
        onClose={this.handleClose}
        aria-labelledby="simple-dialog-title"
        open={true}
        maxWidth={"xl"}
        fullWidth={true}

        // fullScreen={true}
      >
        <div style={{ padding: "30px" }}>{this.renderInputs()}</div>
      </Dialog>
    );
  }
}

function DataRow(props) {
  return (
    <TableRow style={{ ...props.style }}>
      <TableCell
        component="th"
        scope="row"
        style={{ padding: "5px", width: 160 }}
        title={props.title}
      >
        {props.label}
        {props.title && (
          <div style={{ cursor: "pointer" }} onClick={() => alert(props.title)}>
            <HelpIcon size="xsmall" style={{width: 18, height:18}}/>
          </div>
        )}
      </TableCell>
      <TableCell style={{ padding: "5px" }}>{props.inputs} </TableCell>
    </TableRow>
  );
}
