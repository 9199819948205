import React, { Component } from "react";
import { connect } from "react-redux";
import { setNotification } from "../../store/slices/examSlice";
import { getUserQuestionList } from "../../store/slices/examSlice";
import { Link } from "react-router-dom";
import { Button, Fab } from "@mui/material";
import MaterialButton from "@mui/material/Button";
import QuestionCard from "../../components/organisms/questions/questionCard";
import QuestionModal from "../../components/organisms/questions/questionModal";
import AddIcon from "@mui/icons-material/Add";

export class QuestionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionModal: false,
    };
  }
  componentDidMount() {
    this.getQuestions();
  }

  getQuestions = () => {
    const { user, userQuestions } = this.props;

    const filter = { creator_id: user.uid };
    userQuestions(filter);
  };

  renderQuestionModal = () => {
    return (
      <QuestionModal
        onClose={() => {
          this.setState({ questionModal: false });
          this.getQuestions();
        }}
      />
    );
  };

  onAddButtonClick = () => {
    this.setState({ questionModal: true });
  };

  render() {
    const { questionList, inExamPage } = this.props;
    return (
      <div>
        <div>
          <Fab
            variant="extended"
            onClick={this.onAddButtonClick}
            color="secondary"
            style={{ position: "fixed", bottom: 20, right: 70 }}
          >
            <AddIcon />
            Soru Ekle
          </Fab>
        </div>
        {questionList.data.length === 0 ? (
          <div>
            <span>No Question</span>
          </div>
        ) : (
          questionList.data.map((question, question_index) => (
            <QuestionCard
              question={question}
              question_index={question_index}
              getQuestions={this.getQuestions}
            />
          ))
        )}
        {!inExamPage && this.state.questionModal && this.renderQuestionModal()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    questionList: state.exams.userQuestionList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    userQuestions: (filter) => dispatch(getUserQuestionList(filter)),
    setNotification: (notification) => dispatch(setNotification(notification)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionList);
