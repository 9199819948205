import { Container } from "@mui/material";
import React from "react";
import AppBar  from "../../organisms/appBar/appBar";
import Footer from "../../organisms/footer/footer";
import "./homeTemplateStyle.css";

export const HomeTemplate = ({ content }) => {
  // appBar ve footer default olarak burada yer alıyor. Gerekirse sidebar eklenecek.

  return (
    <div className="templateBox">
      <div>
        <AppBar />
        <Container id="container">{content}</Container>
      </div>

      <Footer />
    </div>
  );
};
