import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import examReducer from "./slices/examSlice";

export default configureStore({
  reducer: {
    auth: authReducer,
    exams: examReducer,
  },
});
