import React from "react";
import ReactExport from "react-export-excel";

class Indir extends React.Component {
  render() {
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    const dataSet1 = [
      {
        TC: "11111111",
        "AD-SOYAD": "Ahmet Yilmaz",
      },
      {
        TC: "22222222",
        "AD-SOYAD": "       ",
      },
      {
        TC: "33333333",
        "AD-SOYAD": "Eda Yilmaz",
      },
    ];

    return (
      <div>

        <span>Örnek Excel Formatı:</span>
         <ExcelFile filename="TC Yuklemek Icin Ornek Format">
        <ExcelSheet data={dataSet1} name="Ornek">
          {/* <ExcelColumn label="AD" value="AD" /> */}
          <ExcelColumn label="TC" value="TC" />
          <ExcelColumn label="AD-SOYAD" value="AD-SOYAD" />
          <ExcelColumn />
        </ExcelSheet>
      </ExcelFile>
      </div>
     
    );
  }
}

export default Indir;
