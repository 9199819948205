import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { EXAM_STATUS_TYPES } from "../../common/constants";
import { fetchExam } from "../../db/examMethods";
import "./examIntroPageStyles.css";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { HomeTemplate } from "../../components/templates/homeTemplate/homeTemplate";
import { getExamList, insertExamToList } from "../../store/slices/examSlice";
import { Redirect, useHistory } from "react-router-dom";
import { purchaseExamOnCloudFunctions } from "../../db/cloudFunctionsApiCalls";

const ExamIntroPage = ({ user, match, examList, insertExamToPurchasedList }) => {
  const history = useHistory();

  const [exam, setExam] = useState({});
  const [didUserPurchased, setDidUserPurchased] = useState(false);

  const exam_id = match.params.exam_id;

  useEffect(() => {
    pullExamData();
  }, []);

  useEffect(() => {
    checkIfThisExamPurchased();
    console.log('--- yeni examList', examList);

  }, [examList]);

  const pullExamData = async () => {
    const ex = await fetchExam(exam_id);
    setExam(ex);
  };

  const checkIfThisExamPurchased = async () => {
    let didUserPurchasedThisExam = false;

    if (examList && examList.data) {
      for (let i = 0; i < examList.data.length; i++) {
        const ex = examList.data[i];
        if (ex.id === exam_id) {
          didUserPurchasedThisExam = true;
          break;
        }
      }
    }

    setDidUserPurchased(didUserPurchasedThisExam);
    
  };

  const addExamButtonClicked = async () => {
    // alert("Henüz satın alma işlemi implement edilmedi.");
    const res = (await purchaseExamOnCloudFunctions(exam_id)) ?? {};

    if (res.status === 200) {
      console.log('examList', examList);
      console.log('exam', exam);
      insertExamToPurchasedList(exam);
    }

    if (res.message) alert(res.message);
  };

  if (!exam) {
    return <div>SINAV BULUNAMADI</div>;
  } else if (exam.message) {
    return <div>{exam.message}</div>;
  } else {
    const examImage = (
      <img
        className="examImage"
        src="/assets/images/sampleImage.jpg"
        alt="no_image"
      />
    );
    const header = <div className="header">{exam.header}</div>;
    const desc = <div className="desctiption">{exam.description}</div>;
    const status = exam.status && (
      <div className="status">{EXAM_STATUS_TYPES[exam.status]}</div>
    );
    const startDate = exam.start_date && (
      <div className="startDate">
        {"Başlama tarihi: " + format(exam.start_date, "dd.MM.yyyy hh:mm")}
      </div>
    );
    const endDate = exam.end_date && (
      <div className="endDate">
        {"Bitiş Tarihi: " + format(exam.end_date, "dd.MM.yyyy hh:mm")}
      </div>
    );

    const loginButton = (
      <Link to="/login">Sınava Erişebilmek İçin Oturum Açınız</Link>
    );

    const examButtons = (
      <div className="examButtonsContainer">
        {/* <Link to={"/exam/" + exam.id}>SATIN AL</Link> */}
        {/* <Link to={"/exam/" + exam.id}>SEPETE EKLE</Link> */}

        {!didUserPurchased && (
          <button onClick={addExamButtonClicked}>EKLE</button>
        )}
        {didUserPurchased && (
          <Link to={"/userExams/" + exam_id} target="_blank">
            SINAVA GİT
          </Link>
        )}
        {/* <Link to={"/exam/" + exam.id}>DÜZENLE</Link> */}
        {/* <Link to={"/exam/" + exam.id}>SONUÇLAR</Link> */}
      </div>
    );

    console.log("user", user);
    return (
      <HomeTemplate
        content={
          <div className="examIntroBox">
            {examImage}
            <div className="examDetailsBox">
              {header}
              {desc}
              {status}
              {startDate}
              {endDate}
              {user?.uid ? examButtons : loginButton}
            </div>
          </div>
        }
      />
    );
  }
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  examList: state.exams.examList,
});

const mapDispatchToProps = (dispatch) => {
  return {
    insertExamToPurchasedList: (newExam) => dispatch(insertExamToList(newExam)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExamIntroPage);
