import React, { useState, useEffect } from "react";
import { useTimer } from "react-timer-hook";

const Timer = (props) => {
  const { expiryTimestamp, timesUp } = props;

  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    onExpire: () => timesUp(),
  });

  useEffect(() => {
    restart(expiryTimestamp);
  }, [expiryTimestamp]);

  const secondTime = seconds < 10 ? `0${seconds}` : `${seconds}`;
  const minuteTime = minutes < 10 ? `0${minutes}` : `${minutes}`;

  return (
    <div>
      <span>{minuteTime}</span>:<span>{secondTime}</span>
    </div>
  );
};

export default Timer;
