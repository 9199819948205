import React, { Component } from "react";
import { connect } from "react-redux";
import ExamPage from "./examPage";
import sha256 from "crypto-js/sha256";
import Hex from "crypto-js/enc-hex";

/**
  bu sayfa bizim sistemimize uzaktan erişmek isteyenlerin (ki tozoş ve oyun sınavlarını bunun üstünden ilerletmekteyiz)
  kullandığı sayfa. Gelenin kullandığı linkten bilgileri alıp sisteme yönlendirdiğimiz yer. Yine sistemin EN ÖNEMLİ 
  YERLERİNDEN BİRİ.
*/

export class LinkedExamPage extends Component {
  render() {
    // https://exam-3125b.web.app/linkedExam/kanguru_buklet_1_ALIhIF0Jq8WvdQtNuTn6&kgr34MRfQ09He52W7Gy4BzzfG&kgruserid1&Jackie+CHAN
    // https://sinav.tzv.org.tr/linkedExam/bf2024_sinif_1_YCbnSYjrm8XQP92hTL1c&bf2024_sinif_1_eq_iZxRQnoOOxoTa4i7kD5d&48381_1_2f6ee8a45f7eb7f5ca0b0ee42e4750fc45ca8d971a050ea633b4cfe8f4fbe5f7&Ada+Yaren+G%C3%9CLHAN
    const values = this.props.match.params.values;
    const decodedUrl = values.split("&");
    const examId = decodedUrl[0];
    const examQuestionsId = decodedUrl[1];
    const linkedUserId = decodedUrl[2];
    const userNameInUrl = decodedUrl[3];

    if (decodedUrl.length === 5) {
      // bu tozoş takım sınavıdır. En sonda hash vardır. TODO: tüm sınavlar, ve vendor sınavları bu formata olmalı.
      const incomingHashOfSchoolClassUserName = decodedUrl[4];
      const currentHashOfSchoolClassUserName = sha256(examId + examQuestionsId+  linkedUserId + userNameInUrl)
        .toString(Hex)
        .substring(0, 10);
      if (
        currentHashOfSchoolClassUserName !== incomingHashOfSchoolClassUserName
      ) {
        return (
          <div
            style={{
              textAlign: "center",
              margin: "auto",
              width: "80%",
              maxWidth: "600px",
              fontSize: "2rem",
              color: "red",
              marginTop: "50px",
            }}
          >
            Sınav linkiniz hatalıdır. Lütfen bu sayfayı kapatıp Şampiyona
            sayfasından tekrar sınav linkine tıklayınız.
          </div>
        );
      }
    }

    return (
      <ExamPage
        linkedExamId={examId}
        examQuestionsId={examQuestionsId}
        linkedUserId={linkedUserId}
        linkedUserName={userNameInUrl.replaceAll("+", " ")}
      />
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkedExamPage);
