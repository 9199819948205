import React, { Component } from "react";
import { connect } from "react-redux";
import { ExternalExamTemplate } from "../../components/templates/externalExamTemplate/externalExamTemplate";
import { HomeTemplate } from "../../components/templates/homeTemplate/homeTemplate";
import ExamList from "./examList";

/**
 Burası Kullanıcının satın aldığı sunavları listelemek için kullandığımız sayfa
 */

const content = (
  <div>
    <div>SINAVLARINIZ</div>
    <ExamList />
  </div>
);

export class UserExamsPage extends Component {
  render() {
    const { user } = this.props;
    return (
      <div>
        <HomeTemplate content={content} />
        {/* {user && user.name ? (
          <HomeTemplate content={content} />
        ) : (
          <ExternalExamTemplate content={content} />
        )} */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UserExamsPage);
