export const createStringFromDate=(dateObject)=>{
  if(dateObject)
   return dateObject.toLocaleTimeString("tr-TR", {
      timeZone: "Europe/Istanbul",
      hour: "2-digit",
      minute: "2-digit",
      second:"2-digit",
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });

    else return "";
}