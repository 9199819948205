import { Button } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import "./examCardStyle.css";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { EXAM_STATUS_TYPES } from "../../../common/constants";

export const ExamCard = ({ exam , examStartLink}) => {
  const examImage = (
    <div className="examImageWrapper">
      <img
        className="examImage"
        src="/assets/images/tml.jpg"
        alt="no_image"
      />
    </div>
  );
  const header = (
    <div className="header">{exam.header.toLocaleUpperCase()}</div>
  );
  const desc = <div className="desctiption">{exam.description}</div>;
  const status = exam.status && (
    <div className="status">{EXAM_STATUS_TYPES[exam.status]}</div>
  );
  const startDate = exam.start_date && (
    <div className="startDate">
      {"Başlama tarihi: " + format(exam.start_date, "dd.MM.yyyy HH:mm")}
    </div>
  );
  const endDate = exam.end_date && (
    <div className="endDate">
      {"Bitiş Tarihi: " + format(exam.end_date, "dd.MM.yyyy HH:mm")}
    </div>
  );

  const examButtons = (
    <div className="examButtonsContainer">
      <div>Sınava Git</div>
    </div>
  );

  return (
    <Link to={examStartLink? examStartLink : "/exam/" + exam.id} className="examCardBox">
      {examImage}
      {header}
      {desc}
      {status}
      {startDate}
      {endDate}

      {examButtons}
      {/* <div className="examDetailsBox">
       
      </div> */}
    </Link>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ExamCard);
