import React, { Component } from "react";
import { connect } from "react-redux";
import { ExternalExamTemplate } from "../../components/templates/externalExamTemplate/externalExamTemplate";
import { HomeTemplate } from "../../components/templates/homeTemplate/homeTemplate";
import QuizList from "./quizList";

/**
 Burası bizim ücretsiz quizlerimizi gösterdiğimiz sayfa şu anda bir işlevi yok ama ilerleyen zamanlarda açacağız.

 */

const content = (
  <div>
    <div>Quizler</div>
    <QuizList />
  </div>
);

export class FreeQuizesPage extends Component {
  render() {
    const { user } = this.props;

    return (
      <div>
        {user && user.name ? (
          <HomeTemplate content={content} />
        ) : (
          <ExternalExamTemplate content={content} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FreeQuizesPage);
