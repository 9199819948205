import React, { Component } from "react";
import { connect } from "react-redux";
import { getQuestions, fetchExam } from "../../db/examMethods";
import MaterialButton from "@mui/material/Button";
import QuestionCard from "../../components/organisms/questions/questionCard";
import { HomeTemplate } from "../../components/templates/homeTemplate/homeTemplate";
import { ExternalExamTemplate } from "../../components/templates/externalExamTemplate/externalExamTemplate";
import { setNotification } from "../../store/slices/examSlice";
import Timer from "../../components/organisms/timer/timer";
import QuizFinished from "../../components/organisms/quiz/quizFinished";

/**
 Burası bizim quiz sayfamız. Sınav sayfasından farklı olarak soruları tek tek göstermekteyiz ve yapılan ilemler puanlar 
 sisteme kayıt edilmemekte. Quiz bittikten veya 1 yanlış yapıldıktan sonra ekranda kaç soruya doğru cevap verildiği ve 
 kaç puan alındığı yazılmakta.
 */

export class QuizPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      question: {},
      start: false,
      index: 0,
      time: 0,
      finished: false,
    };
    this.questions = [];
    this.quiz = {};
    this.usersAnswer = {};
    this.score = 0;
    this.trueAnswer = 0;
    this.quizId = this.props.match.params.id;
  }

  async componentDidMount() {
    //quizin exam_question id bilgisini exam içerisine göm quizi çekince filterın içinde gönder sonra direkt soruları çek

    this.quiz = await fetchExam(this.quizId);
    const filter = { exam_questions_id: this.quiz.exam_questions_id };
    this.questions = await getQuestions(filter);
    this.questions = this.questions.filter(
      (q) => q.type !== 0 && q.type !== undefined
    );
    this.shuffleQuestions();
    this.setState({ question: this.questions[0] });
  }

  setTime = () => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + 30); // 30 second timer
    this.setState({ time });
  };

  shuffleQuestions = () => {
    this.questions.sort(() => Math.random() - 0.5);
  };

  startQuiz = () => {
    this.setState({ start: true });
    this.setTime();
  };

  saveQuizAnswer = (response) => {
    const { index, question, time } = this.state;
    if (response === question.answer) {
      const now = new Date();
      //   now.setSeconds(now.getSeconds() + 30); // 30 second timer
      const remainingTime = (time - now) / 1000;
      remainingTime > 20
        ? (this.score += 2)
        : (this.score += remainingTime / 10.0);
      this.score += 3;
      this.trueAnswer += 1;
      if (index + 1 === this.questions.length) {
        alert(`Quiz bitmiştir`);
        this.quizFinished();
      } else {
        this.setState({
          index: index + 1,
          question: this.questions[index + 1],
        });
        this.setTime();
      }
    } else {
      alert(`Cevabınız yanlış. Doğru cevap ${question.answer}`);
      this.quizFinished();
    }
  };

  timesUp = () => {
    alert("Süreniz Doldu");
    this.quizFinished();
  };

  quizFinished = () => this.setState({ finished: true });

  renderQuizExplanation = () => {
    const exp = `1) Quiz ${this.questions.length} sorudan oluşmaktadır.
2) Sorular bittiğinde veya yanlış cevap verdiğinizde quiz bitecektir.
3) Her soru için 30 saniyeniz bulunmaktadır.
4) Aldığınız puan quiz bittikten sonra size gösterilecektir.`;

    if (exp) {
      return (
        <p
          style={{
            textAlign: "start",
            border: "solid chocolate",
            whiteSpace: "pre-wrap",
            borderRadius: 15,
            padding: 15,
            maxWidth: 700,
          }}
        >
          {exp}
        </p>
      );
    }
  };

  renderQuestionNumber = () => {
    const { index } = this.state;
    return (
      <span>
        {index + 1}/{this.questions.length}
      </span>
    );
  };

  restartQuiz = () => {
    this.shuffleQuestions();
    this.score = 0;
    this.trueAnswer = 0;
    this.setState({
      question: this.questions[0],
      start: false,
      index: 0,
      time: 0,
      finished: false,
    });
  };

  render() {
    const { question, start, index, time, finished } = this.state;
    const isStartButtonVisible = start === false;
    const content = (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {!finished && isStartButtonVisible && this.renderQuizExplanation()}
        {isStartButtonVisible && (
          <MaterialButton
            variant="contained"
            color="primary"
            onClick={this.startQuiz}
          >
            BAŞLA
          </MaterialButton>
        )}
        <div
          style={{
            fontSize: "25px",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            maxWidth: 685,
          }}
        >
          {!finished && !isStartButtonVisible && this.renderQuestionNumber()}
          {!finished && !isStartButtonVisible && (
            <Timer expiryTimestamp={time} timesUp={this.timesUp} />
          )}
        </div>
        {!finished && !isStartButtonVisible && (
          <QuestionCard
            key={index}
            inExamPage
            inQuizPage
            question={question}
            question_index={index}
            examId={this.quiz.id}
            examStatus={this.quiz.status}
            saveQuizAnswer={this.saveQuizAnswer}
          />
        )}
        {finished && (
          <QuizFinished
            score={this.score}
            quizId={this.quizId}
            trueAnswers={this.trueAnswer}
            onRestart={this.restartQuiz}
          />
        )}
      </div>
    );
    return (
      <div style={{ backgroundColor: "#dbdad6" }}>
        <HomeTemplate content={content} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setNotification: (notification) => dispatch(setNotification(notification)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuizPage);
