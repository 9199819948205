import React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import { EXAM_STATUS_TYPES } from "../../../common/constants";
import ExamCard from "../examCard/examCard";


const ExamTable = (props) => {
  const { exams, created, name, kanguru, tc } = props;

  let sortedExams = [...exams.data];
   sortedExams= sortedExams.sort((e1, e2) => {
    if (e1.start_date && e2.start_date) {
      let dateDif = e2.start_date - e1.start_date;
      if (dateDif === 0) {
        let e1Desc = e1.description ?? "";
        let e2Desc = e2.description ?? "";
        return e1Desc.localeCompare(e2Desc);
      } else return dateDif;
    } else return -1;
  })

  const generateRows = () => {
    let rows = [];
    sortedExams
      .map((ex) => {
        const {
          id,
          description,
          header,
          start_date,
          end_date,
          exam_questions_id,
          status,
        } = ex;
        const editButton = (
          <Link
            to={{
              pathname: `/userCreatedExams/${id}`,
            }}
            style={{ textDecoration: "none" }}
          >
            <Button color="primary" variant="contained">
              Düzenle
            </Button>
          </Link>
        );

        const nameForUrl = name && name.trim().replaceAll(" ", "+");
        const linkKanguruTest =
          "/linkedExam/" +
          id +
          "&" +
          exam_questions_id +
          "&" +
          tc +
          "&" +
          nameForUrl;

        const goToKanguruTestButton = (
          <Link
            to={{
              pathname: linkKanguruTest,
            }}
            style={{ textDecoration: "none" }}
          >
            <Button color="primary" variant="contained">
              Teste Git
            </Button>
          </Link>
        );

        const goToTestButton = (
          <Link
            to={{
              pathname: `/userExams/${id}`,
            }}
            style={{ textDecoration: "none" }}
          >
            <Button color="primary" variant="contained">
              Teste Git
            </Button>
          </Link>
        );

        const resultsButton = (
          <Link
            to={{
              pathname: `/result/${id}`,
            }}
            style={{ textDecoration: "none" }}
          >
            <Button color="primary" variant="contained">
              Sonuçlar
            </Button>
          </Link>
        );

        let row = (
          <TableRow key={id}>
            <TableCell component="th" scope="row">
              {header}
            </TableCell>
            <TableCell component="th" scope="row">
              {description}
            </TableCell>
            <TableCell component="th" scope="row">
              {start_date.toLocaleTimeString("tr-TR", {
                timeZone: "Europe/Istanbul",
                hour: "2-digit",
                minute: "2-digit",
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              })}
            </TableCell>
            <TableCell component="th" scope="row">
              {end_date.toLocaleTimeString("tr-TR", {
                timeZone: "Europe/Istanbul",
                hour: "2-digit",
                minute: "2-digit",
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              })}
            </TableCell>

            <TableCell
              component="th"
              scope="row"
              style={{
                backgroundColor:
                  status === 3 ? "green" : status === 2 ? "pink" : "",
              }}
            >
              {EXAM_STATUS_TYPES[status]}
            </TableCell>

            <TableCell component="th" scope="row">
              {!kanguru && goToTestButton}
              {kanguru && goToKanguruTestButton}
              {created && editButton}
              {created && resultsButton}
            </TableCell>
          </TableRow>
        );

        rows.push(row);
      });

    return rows;
  };

  const generateCardsForExaminee = ()=>{
    
    return <Grid container spacing={3} justifyContent="center" >
{sortedExams.map(ex=>{
    const {
      id,
      description,
      header,
      start_date,
      end_date,
      exam_questions_id,
      status,
    } = ex;
    const nameForUrl = name && name.trim().replaceAll(" ", "+");
        const linkKanguruTest =
          "/linkedExam/" +
          id +
          "&" +
          exam_questions_id +
          "&" +
          tc +
          "&" +
          nameForUrl;

      return  <Grid item xs={12} sm={6} md={4}><ExamCard exam={ex} examStartLink={linkKanguruTest} /> </Grid> 
    })}
    </Grid>
  
  }

  if(created)
  return <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Başlık</TableCell>
            <TableCell>Açıklama</TableCell>
            <TableCell>Başlangıç Tarihi</TableCell>
            <TableCell>Bitiş Tarihi</TableCell>
            <TableCell>Durum</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{generateRows()}</TableBody>
      </Table>
    </TableContainer>
  

  else return generateCardsForExaminee();
};

export default ExamTable;
