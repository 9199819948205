import React from "react";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import { useSelector, useDispatch } from "react-redux";
import { setNotification } from "../../../store/slices/examSlice";

export const FeedbackSnack = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const nextNotification = useSelector((state) => state.exams.nextNotification);

  if (nextNotification) {
    enqueueSnackbar(nextNotification.message, {
      variant: nextNotification.variant,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
    dispatch(setNotification(null));
  }

  return null;
};
